import { OnChanges } from '@angular/core/src/metadata/lifecycle_hooks';
import { Component, OnInit, Input } from '@angular/core';
import { Helper } from '../../../shared/helper';
import { Logger } from 'app/shared';
import { Chart } from 'chart.js';

@Component({
  selector: 'htm-multi-legend',
  templateUrl: './multi-legend.component.html',
  styleUrls: ['./multi-legend.component.css']
})
export class MultiLegendComponent implements OnInit, OnChanges {
  @Input() chart;
  private _legends = [];
  constructor(private logger: Logger, private helper: Helper) { }
  get legends() {return this._legends}
  ngOnInit() {
    this.logger.log('MultiLegendComponent/ngOnInit()');
  }

  ngOnChanges(changes) {
    if (!this.helper.isNullOrUndefined(this.chart)) {
      this.update();
    }
  }

  update() {
    const self = this
    const setData = new Set();
    self._legends = []

    this.chart.config.data.datasets.forEach(function (dataset, index) {
      var meta = self.chart.controller.getDatasetMeta(index);
      meta.data.forEach(function(bar) {
        let model = bar._model;
        let label = dataset.stack;
        if ((label !== undefined) && (!setData.has(model.label + ';' + label))) {
          const tmp = { x: model.x, label: label, width: model.width }
          self.legends.push(tmp)
          setData.add(model.label + ';' + label )
        }
      });
    });
  }
}
