import { ElementFinder } from "protractor";
import { ENERGY_SERVICES, FILTERS_DICT } from "./const";
// export const apiVersion = '/api/v1';
// export const URLAPI = apiURL + apiVersion;

// export const clientURLPROD = 'https://dev-dashboard.ncsa.ch';
// export const clientURLDEV = 'http://localhost:4200';
// export const clientURL = clientURLPROD

/* CHART IDS */
export const idChartEvolution = "evolution";
export const idChartActual = "actual";
export const idChartMix = "mix";
export const idChartRenewable = "renewable";
export const idChartLocal = "local";
export const idChartStrategy = "global-strategy-area";
export const idChartStrategyElec = "elec-strategy-area";
export const idChartStrategyHeat = "heat-strategy-area";
export const idChartStrategyBuilding = "building-strategy-area";
export const idChartStrategyMobility = "mobility-strategy-area";
export const idChartVechiculeFuelNb = "mobility-fuel-vehicles";
export const idChartVechiculeTypeNb = "nb-vehicles-type";
export const idChartNetworksGeneral = "networks-general";
export const idChartRenHeatDoughnut = "ren_doughnut_heat";
export const idChartRenElecDoughnut = "ren_doughnut_elec";
export const idChartRenMobilityDoughnut = "ren_doughnut_mobility";
export const idChartElecProductConsumption = "electricity-consumption";
export const idChartRenEnergyProd = "ren-energy-prod";
export const idChartActions = "actions-general";
export const idChartSubsidies = "subsidies";
export const idChartS2000W = "s2000W";
export const idChartPublicBdg = "public-building";
export const idChartHeaterSubstitution = "heater_substitution";
export const idChartElecProd = "electricity_production";
export const idChartPvTarget = "target_value_pv";
export const idPublicConsumptionByBuildingType =
  "public_consumption_by_building_type";
export const idPublicConsumptionByEnergeticAgent =
  "public_consumption_by_energetic_agent";

/* ENERGY SERVICE FILTER OPTIONS */
export const heatEnergyService = "heat";
export const electricityEnergyService = "electricity";
export const mobilityEnergyService = "mobility";

/* ENERGY SERVICE FOR BUILDING FILTER OPTIONS */
export const heatEnergyService_bdg = "heat-bdg";
export const electricityEnergyService_bdg = "electricity-bdg";

/* ENERGY TYPE FILTER OPTIONS */
export const heatEnergyType = "heat";
export const electricityEnergyType = "electricity";

/* VEHICLE FUEL FILTER OPTIONS */
// export const totalVF = 'total';
export const gasolineVF = "gasoline";
export const dieselVF = "diesel";
export const hybridVF = "hybridVF";
export const elecVF = "elecVF";
export const gasVF = "gasVF";
export const h2VF = "h2VF";
export const otherVF = "otherVF";

/* VEHICLE TYPE FILTER OPTIONS */
// export const otherVehicleType = 'total';
export const carsVT = "car";
export const motorbikesVT = "motorbike";
export const scootersVT = "scooter";
export const trucksVT = "truck";
export const othersVT = "other";

/* SIA BUILDING ASSIGNMENT FILTER OPTIONS */
export const siaMultiDwelling = "sia_multi_dwelling";
export const siaIndividualDwelling = "sia_individual_dwelling";
export const siaAdministration = "sia_administration";
export const siaSchool = "sia_school";
export const siaBusiness = "sia_business";
export const siaRestaurant = "sia_restaurant";
export const siaGatheringPlace = "sia_gathering_place";
export const siaHospital = "sia_hospital";
export const siaIndustry = "sia_industry";
export const siaWarehouse = "sia_warehouse";
export const siaSportsFacility = "sia_sports_facility";
export const siaIndoorSwimmingPool = "sia_indoor_swimming_pool";

/* ENERGETIC AGENT FILTER OPTIONS */
export const eaSolar = "ea_solar";
export const eaGas = "ea_gas";
export const eaFuelOil = "ea_fuel_oil";
export const eaElectricity = "ea_electricity";
export const eaWood = "ea_wood";
export const eaHeatPump = "ea_heat_pump";
export const eaDHN = "ea_dhn";
export const eaOther = "ea_other_agent";

/* DEFAULT FILTER OPTION VALUES */
export const defaultTimeLine = "2016";
export const defaultSizeChart = 250;
export const defaultPanelSize = 525;
export const defaultIdDisplayed = 1;
export const defaultDataName = "DATA.regio";

// DASHBOARD
export const defaultDashboardType = "consommation";

// RADIO BUTTONS
// don't use const to avoid :error TS2367: when setting the default buttons
export let defaultValueType = "habitant";
export let defaultIndicator = "energy";
export let defaultPerimeter = "territory";
export let defaultNetworksInd = "global";
export let defaultActionCategory = "total";

// CHECKBOX BUTTONS
// default energy service is defined during the filter "setup"
export const defaultEnergyServiceBdg = [
  electricityEnergyService_bdg,
  heatEnergyService_bdg
];
export const defaultEnergyType = [electricityEnergyType, heatEnergyType];
export const defaultVehicleFuels = [gasolineVF, dieselVF, hybridVF, elecVF, gasVF, h2VF, otherVF];
export const defaultVehicleTypes = [
  carsVT,
  motorbikesVT,
  scootersVT,
  trucksVT,
  othersVT
];
export const defaultSiaAssignment = [
  siaMultiDwelling,
  siaIndividualDwelling,
  siaAdministration,
  siaSchool,
  siaBusiness,
  siaRestaurant,
  siaGatheringPlace,
  siaHospital,
  siaIndustry,
  siaWarehouse,
  siaSportsFacility,
  siaIndoorSwimmingPool
];
export const defaultEnergeticAgent = [
  eaSolar,
  eaGas,
  eaFuelOil,
  eaElectricity,
  eaWood,
  eaHeatPump,
  eaDHN,
  eaOther
];

/* FILTER TRANSLATION SETTING */
export const translateFilterType = "FILTERS.TYPE.";
export const translateFilterValue = "FILTERS.VALEURS.";
export const translateFilterEnergie = "FILTERS.ENERGIE.";
export const translateFilterPerimeter = "FILTERS.PERIMETER.";
export const translateFilterNetworksInd = "FILTERS.NETWORKIND.";
export const translateFilterVehicleFuel = "FILTERS.VEHICLEFUELS.";
export const translateFilterVehicleType = "FILTERS.VEHICLETYPES.";
export const translateFilterActionCategory = "FILTERS.ACTIONCATEGORY.";
export const translateFilterSiaAssignment = "FILTERS.SIAASSIGNMENT.";
export const translateFilterEnergeticAgent = "FILTERS.ENERGETICAGENT.";

/* FILTER SETUP -> Create the list containing all the buttons for the filter */
/* ENERGY SERVICE FILTER SETUP */
//export const defaultEnergyService = [electricityEnergyService, heatEnergyService];
var enServiceList: any = [];
var enServiceListButt: any = [];
for (var enService in ENERGY_SERVICES) {
  if (ENERGY_SERVICES[enService]["id"] == "electricity") {
    var classButton = "button-elec";
    var translateService = translateFilterEnergie + "ELECT";
  } else if (ENERGY_SERVICES[enService]["id"] == "mobility") {
    var classButton = "button-mobil";
    var translateService = translateFilterEnergie + "MOBILITE";
  } else if (ENERGY_SERVICES[enService]["id"] == "heat") {
    var classButton = "button-heat";
    var translateService = translateFilterEnergie + "CHALEUR";
  }
  enServiceList.push(ENERGY_SERVICES[enService]["id"]);
  enServiceListButt.push({
    id: ENERGY_SERVICES[enService]["id"],
    class: classButton,
    selected: true,
    translate: translateService
  });
}
export const buttonsEnergyService: any = enServiceListButt;
export const defaultEnergyService = enServiceList;

/* ENERGY SERVICE FOR BUILDING FILTER SETUP */
var enServiceListForBdg: any = [];
for (var enService in defaultEnergyServiceBdg) {
  if (defaultEnergyServiceBdg[enService] == electricityEnergyService_bdg) {
    var classButton = "button-elec";
    var translateService = translateFilterEnergie + "ELECT";
  } else if (defaultEnergyServiceBdg[enService] == heatEnergyService_bdg) {
    var classButton = "button-heat";
    var translateService = translateFilterEnergie + "CHALEUR";
  }
  var idButton = defaultEnergyServiceBdg[enService];
  enServiceListForBdg.push({
    id: idButton,
    class: classButton,
    selected: false,
    translate: translateService
  });
}
export const buttonsEnergyServiceForBdg: any = enServiceListForBdg;

/* ENERGY TYPE FILTER SETUP */
var energyType: any = [];
for (var enType in defaultEnergyType) {
  if (defaultEnergyType[enType] == electricityEnergyType) {
    var classButton = "button-elec";
    var translateService = translateFilterEnergie + "ELECT";
  } else if (defaultEnergyType[enType] == heatEnergyType) {
    var classButton = "button-heat";
    var translateService = translateFilterEnergie + "CHALEUR";
  }
  var idButton = defaultEnergyType[enType];
  energyType.push({
    id: idButton,
    class: classButton,
    selected: false,
    translate: translateService
  });
}
export const buttonsEnergyType: any = energyType;

/* VALUE TYPE FILTER SETUP */
export const buttonsValueType: any = [
  {
    id: "habitant",
    name: "Valeurs / habitant",
    translate: translateFilterValue + "HABITANT",
    selected: defaultValueType == "habitant"
  },
  {
    id: "absolute",
    name: "Valeurs absolues",
    translate: translateFilterValue + "ABSOLUE",
    selected: defaultValueType == "absolute"
  },
  {
    id: "pourcent",
    name: "%",
    translate: translateFilterValue + "POURCENT",
    selected: defaultValueType == "pourcent"
  }
];

/* INDICATOR FILTER SETUP */
export const buttonsIndicator: any = [
  {
    id: "energy",
    name: "Energie",
    translate: translateFilterType + "ENERGY",
    selected: defaultIndicator == "energy"
  },
  {
    id: "co2",
    name: "CO2",
    translate: translateFilterType + "CO2",
    selected: defaultIndicator == "co2"
  }
];

/* NETWORK INDICATOR FILTER SETUP */
export const buttonsNetworksIndService: any = [
  {
    id: "global",
    name: "Global_network",
    translate: translateFilterNetworksInd + "GLOBAL",
    selected: defaultNetworksInd == "global"
  },
  {
    id: "mix-test",
    name: "Mix_network",
    translate: translateFilterNetworksInd + "MIX",
    selected: defaultNetworksInd == "mix-test"
  },
  {
    id: "CO2_red",
    name: "CO2Red_Network",
    translate: translateFilterNetworksInd + "CO2RED",
    selected: defaultNetworksInd == "CO2_red"
  }
];

/* PERIMETER FILTER SETUP */
export const buttonsPerimeterService: any = [
  {
    id: "territory",
    name: "Territory",
    translate: translateFilterPerimeter + "TERRITORY",
    selected: defaultPerimeter == "territory"
  },
  {
    id: "municipality",
    name: "Municipality",
    translate: translateFilterPerimeter + "MUNICIPALITY",
    selected: defaultPerimeter == "municipality"
  }
];

/* ACTION CATEGORY FILTER SETUP */
export const buttonsActionCategory: any = [
  {
    id: "total",
    name: "Total",
    translate: translateFilterActionCategory + "TOTAL",
    selected: defaultActionCategory == "total"
  },
  {
    id: "municipality",
    name: "Municipality",
    translate: translateFilterActionCategory + "MUNICIPALITY",
    selected: defaultActionCategory == "municipality"
  },
  {
    id: "fund",
    name: "Fund",
    translate: translateFilterActionCategory + "FUND",
    selected: defaultActionCategory == "fund"
  }
];

/* SIA ASSIGNMENT FILTER SETUP */
var siaAssignmentList: any = [];
for (var assignment in defaultSiaAssignment) {
  switch (defaultSiaAssignment[assignment]) {
    case siaMultiDwelling:
      var classButton = "button-siaMultiDwelling";
      var translateAssignment = translateFilterSiaAssignment + "MULTI_DWELLING";
      break;

    case siaIndividualDwelling:
      var classButton = "button-siaIndividualDwelling";
      var translateAssignment =
        translateFilterSiaAssignment + "INDIVIDUAL_DWELLING";
      break;

    case siaAdministration:
      var classButton = "button-siaAdministration";
      var translateAssignment = translateFilterSiaAssignment + "ADMINISTRATION";
      break;

    case siaSchool:
      var classButton = "button-siaSchool";
      var translateAssignment = translateFilterSiaAssignment + "SCHOOL";
      break;

    case siaBusiness:
      var classButton = "button-siaBusiness";
      var translateAssignment = translateFilterSiaAssignment + "BUSINESS";
      break;

    case siaRestaurant:
      var classButton = "button-siaRestaurant";
      var translateAssignment = translateFilterSiaAssignment + "RESTAURANT";
      break;

    case siaGatheringPlace:
      var classButton = "button-siaGatheringPlace";
      var translateAssignment =
        translateFilterSiaAssignment + "GATHERING_PLACE";
      break;

    case siaHospital:
      var classButton = "button-siaHospital";
      var translateAssignment = translateFilterSiaAssignment + "HOSPITAL";
      break;

    case siaIndustry:
      var classButton = "button-siaIndustry";
      var translateAssignment = translateFilterSiaAssignment + "INDUSTRY";
      break;

    case siaWarehouse:
      var classButton = "button-siaWarehouse";
      var translateAssignment = translateFilterSiaAssignment + "WAREHOUSE";
      break;

    case siaSportsFacility:
      var classButton = "button-siaSportsFacility";
      var translateAssignment =
        translateFilterSiaAssignment + "SPORTS_FACILITY";
      break;

    case siaIndoorSwimmingPool:
      var classButton = "button-siaIndoorSwimmingPool";
      var translateAssignment =
        translateFilterSiaAssignment + "INDOOR_SWIMMING_POOL";
      break;
  }
  siaAssignmentList.push({
    id: defaultSiaAssignment[assignment],
    class: classButton,
    selected: false,
    translate: translateAssignment
  });
}
export const buttonsSiaAssignment: any = siaAssignmentList;

/* ENERGETIC AGENT FILTER SETUP */
var energeticAgentList: any = [];
for (var agent in defaultEnergeticAgent) {
  switch (defaultEnergeticAgent[agent]) {
    case eaSolar:
      var classButton = "button-eaSolar";
      var translateEA = translateFilterEnergeticAgent + "SOLAR";
      break;

    case eaGas:
      var classButton = "button-eaGas";
      var translateEA = translateFilterEnergeticAgent + "GAS";
      break;

    case eaFuelOil:
      var classButton = "button-eaFuelOil";
      var translateEA = translateFilterEnergeticAgent + "FUEL_OIL";
      break;

    case eaElectricity:
      var classButton = "button-eaElectricity";
      var translateEA = translateFilterEnergeticAgent + "ELECTRICITY";
      break;

    case eaWood:
      var classButton = "button-eaWood";
      var translateEA = translateFilterEnergeticAgent + "WOOD";
      break;

    case eaHeatPump:
      var classButton = "button-eaHeatPump";
      var translateEA = translateFilterEnergeticAgent + "HEAT_PUMP";
      break;

    case eaDHN:
      var classButton = "button-eaDHN";
      var translateEA = translateFilterEnergeticAgent + "DHN";
      break;

    case eaOther:
      var classButton = "button-eaOther";
      var translateEA = translateFilterEnergeticAgent + "OTHER";
      break;
  }
  energeticAgentList.push({
    id: defaultEnergeticAgent[agent],
    class: classButton,
    selected: false,
    translate: translateEA
  });
}
export const buttonsEnergeticAgent: any = energeticAgentList;

/* VEHICLE FUEL FILTER SETUP */
var vehiclefuelsList: any = [];
for (var vf in defaultVehicleFuels) {
  /* if (defaultVehicleFuels[vf] == totalVF){
    var classButton = 'button-allvf';
    var translateFuel = translateFilterVehicleFuel + 'TOTAL';
  } */
  if (defaultVehicleFuels[vf] == gasolineVF) {
    var classButton = "button-gasolinevf";
    var translateFuel = translateFilterVehicleFuel + "GASOLINE";
  } else if (defaultVehicleFuels[vf] == dieselVF) {
    var classButton = "button-dieselvf";
    var translateFuel = translateFilterVehicleFuel + "DIESEL";
  } else if (defaultVehicleFuels[vf] == elecVF) {
    var classButton = "button-elecvf";
    var translateFuel = translateFilterVehicleFuel + "ELEC";
  } else if (defaultVehicleFuels[vf] == gasVF) {
    var classButton = "button-gasvf";
    var translateFuel = translateFilterVehicleFuel + "GAS";
  } else if (defaultVehicleFuels[vf] == hybridVF) {
    var classButton = "button-hybridvf";
    var translateFuel = translateFilterVehicleFuel + "HYBRID";
  } else if (defaultVehicleFuels[vf] == otherVF) {
    var classButton = "button-othervf";
    var translateFuel = translateFilterVehicleFuel + "OTHERS";
  } else if (defaultVehicleFuels[vf] == h2VF) {
    var classButton = "button-h2vf";
    var translateFuel = translateFilterVehicleFuel + "H2";
  }
  vehiclefuelsList.push({
    id: defaultVehicleFuels[vf],
    class: classButton,
    selected: false,
    translate: translateFuel
  });
}
export const buttonsVehicleFuelService: any = vehiclefuelsList;

/* VEHICLE TYPE FILTER SETUP */
var vehicleTypesList: any = [];
for (var vt in defaultVehicleTypes) {
  if (defaultVehicleTypes[vt] == carsVT) {
    var classButton = "button-carsvt";
    var translateType = translateFilterVehicleType + "CAR";
  } else if (defaultVehicleTypes[vt] == motorbikesVT) {
    var classButton = "button-motorbikevt";
    var translateType = translateFilterVehicleType + "MOTORBIKE";
  } else if (defaultVehicleTypes[vt] == scootersVT) {
    var classButton = "button-scootervt";
    var translateType = translateFilterVehicleType + "SCOOTER";
  } else if (defaultVehicleTypes[vt] == trucksVT) {
    var classButton = "button-truckvt";
    var translateType = translateFilterVehicleType + "TRUCK";
  } else if (defaultVehicleTypes[vt] == othersVT) {
    var classButton = "button-othervt";
    var translateType = translateFilterVehicleType + "OTHER";
  }
  vehicleTypesList.push({
    id: defaultVehicleTypes[vt],
    class: classButton,
    selected: false,
    translate: translateType
  });
}
export const buttonsVehicleTypeService: any = vehicleTypesList;

export const languages = [
  { id: "it", name: "Italiano", webview_name: "IT", selected: false },
  { id: "fr", name: "Français", webview_name: "FR", selected: false },
  { id: "en", name: "English", webview_name: "EN", selected: false },
  { id: "de", name: "Deutsch", webview_name: "DE", selected: false }
];
