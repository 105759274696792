/**
 * Created by lesly on 19.06.17.
 */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class LoaderService {
  public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public main_loader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  display(value: boolean) {
    this.status.next(value);
  }
  displayMainLoader(value: boolean) {
    this.main_loader.next(value);
  }
}
