import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/shared/helper';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-networks-chart',
  templateUrl: './networks-chart.component.html',
  styleUrls: ['./networks-chart.component.css']
})
export class NetworksChartComponent implements OnInit {
  @ViewChild('chartNetworks') canvas;
  @Input() type;
  @Input() chart_id;
  @Input() options;
  @Input() indicator;
  @Input() sample_data;
  @Input() gas_debt;
  @Input() show_network_mix;
  @Input() chartData;
  @Input() value_type;
  @Input() unit;
  
  public networksChartOptions: { responsive: boolean; };
  public networksChartData: any;
  private chart: Chart;
  private yAxes_def;
  private yAxeLabel: string;
  private networksChartDatasets; 
  private show_gas_debt: boolean;
  
  constructor(private helper: Helper, private translateService: TranslateService) { }

  ngOnInit() {
  }

  ngOnChanges(){
    /* if (this.chartData.networkInd == "global") {
      this.show_network_mix = false;
    }
    else if (this.chartData.networkInd == "mix") {
      this.show_network_mix = true;
    } */
    this.defineIfShowgasDebt();
    this.createNetworksChart();
  }

  getTranslatedLabel(labelToTranslate) {
    return this.translateService.instant('DATA.' + labelToTranslate);
  }

  createNetworksChart() {
    this.resetChart();
    if (this.sample_data){
      this.networksChartData = this.getSampleData();
    }
    else {
      if (this.chartData) {
        this.networksChartData = {labels : this.chartData["labels"]};
        this.networksChartData['datasets'] = this.chartData["datasets"];
       
        var i;
        for (i = 0; i < this.networksChartData['datasets'].length; i++) {
          this.networksChartData['datasets'][i]['label'] = this.getTranslatedLabel(this.networksChartData['datasets'][i]['label']);
          if (this.value_type != 'pourcent' && this.indicator == 'energy'){
            var j;
            for (j = 0; j< this.networksChartData['datasets'][i]['data'].length; j++){
              this.networksChartData['datasets'][i]['data'][j] *= 1000;
            }
          }
        }
      }
    }
    this.networksChartOptions = {
      responsive: true
    }
    // DEFINE Y LABEL WITH UNIT
    if (this.indicator == "energy"){var y_label_type = "Y_LABEL_NAME"} else if (this.indicator == "co2") {var y_label_type = "Y_LABEL_NAME_CO2"}
    this.yAxeLabel = this.translateService.instant(`DASHBOARD.CHARTS.${ this.chart_id }.${ y_label_type }`);
    var unit_from_config = this.helper.getUnitIdFromFilterValue(this.value_type, this.unit[this.indicator]);
    this.yAxeLabel += this.translateService.instant(`UNITS.${ unit_from_config["translate_unit"] }`);

    // Define behavior of tooltip for the chart
    this.options.tooltips.callbacks.label = (item, data) => {
      let unit;
      let translate_var = this.value_type
      unit = this.translateService.instant(`UNITS.${ unit_from_config["translate_unit"] }`);
      return [`${data.datasets[item.datasetIndex].label} : ${this.helper.getThousandSeparatorFromNumber(item.yLabel)} `
      + unit]
    }

    this.yAxes_def = [{
      stacked: true,
      display: true,
      id: "main_ind",
      scaleLabel: {
        display: true,
        labelString: this.yAxeLabel,
        fontColor: 'black',
        fontSize: 14
        },
      ticks: {
        fontColor: 'black',
        fontSize: 12,
        callback: value => {
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
        }
      }
    }]
    this.options['scales']['yAxes'] = this.yAxes_def;
    this.options.responsive = true;
    this.options['maintainAspectRatio'] = true;
    this.options.legend.display = true;
    
    const ctx = this.canvas.nativeElement.getContext('2d');
    this.chart = new Chart(ctx, {
      type: this.type,
      data: this.networksChartData,
      options: this.options
    });
  }
  
  resetChart() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = void 0;
    }
  }

  private defineIfShowgasDebt(){
    if (this.gas_debt){
      this.show_gas_debt = true;
    }
  }

  getSampleData() {
    let networksChartLabels = [2017, 2018, 2019, 2020];
    let gazData = [104490000, 98973204, 92026299, 90000000];
    let dhnData = [4500000, 5475000, 6450000, 8250000];
    var dcnData = [0, 10000000, 50000000, 50000000];
    
    var sampleData = {
      labels: networksChartLabels,
    }
    if (!this.show_network_mix) {
      this.networksChartDatasets = [
        {
          label: 'Réseau de gaz',
          data: gazData,
          stack: "gas",
          backgroundColor: 'rgba(50, 50, 50, 0.3)',
          borderColor: 'rgba(50, 50, 50)',
          borderWidth: 1,
          lineTension: 0,
          yAxisID: 'main_ind'
        },
        {
          label: 'Réseaux CAD',
          data: dhnData,
          stack: "dhn",
          backgroundColor: 'rgba(164, 24, 211, 0.3)',
          borderColor: 'rgba(164, 24, 211)',
          borderWidth: 1,
          lineTension: 0,
          yAxisID: 'main_ind'
        }
      ];
    }
    else if (this.show_network_mix) {
      this.networksChartDatasets = [
      {
        label: "Gaz - Gaz naturel",
        data: gazData.map(function(x) { return x * 0.95; }),
        stack: "gaz",
        backgroundColor: 'rgba(50, 50, 50, 0.3)',
        borderColor: 'rgba(50, 50, 50)',
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'mwh'
      },
      {
        label: 'Gaz - Biogaz',
        data: gazData.map(function(x) { return x * 0.05; }),
        stack: "gaz",
        backgroundColor: 'rgba(204, 153, 51, 0.3)',
        borderColor: 'rgba(204, 153, 51)',
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'mwh'
      },
      {
        label: 'CAD - Bois',
        data: dhnData.map(function(x) { return x * 0.5; }),
        stack: "dhn",
        backgroundColor: 'rgba(153, 102, 51, 0.3)',
        borderColor: 'rgba(153, 102, 51)',
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'mwh'
      },
      {
        label: 'CAD - Gaz',
        data: dhnData.map(function(x) { return x * 0.2; }),
        stack: "dhn",
        backgroundColor: 'rgba(50, 50, 50, 0.3)',
        borderColor: 'rgba(50, 50, 50)',
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'mwh'
      },
      {
        label: 'CAD - PAC',
        data: dhnData.map(function(x) { return x * 0.3; }),
        stack: "dhn",
        backgroundColor: 'rgba(51, 204, 153, 0.3)',
        borderColor: 'rgba(51, 204, 153)',
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'mwh'
      },
      {
        label: 'FAD - Eau lac',
        data: dcnData.map(function(x) { return x * 0.9; }),
        stack: "dhn",
        backgroundColor: 'rgba(0, 153, 204, 0.3)',
        borderColor: 'rgba(0, 153, 204)',
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'mwh'
      },
      {
        label: 'FAD - Elec',
        data: dcnData.map(function(x) { return x * 0.1; }),
        stack: "dhn",
        backgroundColor: 'rgba(0, 51, 153, 0.3)',
        borderColor: 'rgba(0, 51, 153)',
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'mwh'
      },
      ];
    }
    if (this.show_gas_debt){
      this.networksChartDatasets.push({
        label: 'Dette du gaz',
        pointRadius: 0,
        data: [1000000, 900000, 850000, 830000, 820000, 820000],
        type: 'line',
        fill: false,
        fillStyle: 'rgba(50, 50, 50, 0)',
        borderColor: [
          'red'
        ],
        borderWidth: 2,
        borderDash: [5, 8],
        lineTension: 0,
        yAxisID: 'debt-CHF'
      })
    }
    sampleData['datasets']= this.networksChartDatasets
    return sampleData;
  }
}
