import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/shared/helper';
import { Logger } from 'app/shared/services/logger.service';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-public-bdg-chart',
  templateUrl: './public-bdg-chart.component.html',
  styleUrls: ['./public-bdg-chart.component.css']
})

export class PublicBdgChartComponent implements OnInit {
  @ViewChild('chartPublicBdg') canvas;
  @Input() type;
  @Input() chart_id;
  @Input() options;
  @Input() indicator;
  @Input() sample_data;
  @Input() show_network_mix
  @Input() chartData;
  @Input() value_type;
  @Input() unit;

  public publicBdgData: any;
  private chart: Chart;
  private yAxeLabel_ind: string;
  private publicBdgDatasets;
  private yAxeLabel_amount: string;
  
  constructor(private helper: Helper, private translateService: TranslateService) { }

  ngOnInit() {
  }

  ngOnChanges(){
    this.createPublicBdgChart();
  }

  getTranslatedLabel(labelToTranslate) {
    return this.translateService.instant('DATA.' + labelToTranslate);
  }

  createPublicBdgChart(){
    this.resetChart();
    if (this.sample_data){
      this.publicBdgData = this.getSampleData();
    }
    else {
      let sum_instead_of_details = false;
      if (this.chartData) {
        this.publicBdgData = {labels : this.chartData["labels"]};
        this.publicBdgData['datasets'] = this.chartData["datasets"];
        var i;
        for (i = 0; i < this.publicBdgData['datasets'].length; i++) {
          this.publicBdgData['datasets'][i]['label'] = this.getTranslatedLabel(this.publicBdgData['datasets'][i]['label']);
          // this.areaChartData['datasets'][i]['label'] = "TEST";
        }
      }
    }

    // DEFINE Y LABEL WITH UNIT
    if (this.indicator == "energy"){var y_label_type = "Y_LABEL_NAME_IND_EN"} else if (this.indicator == "co2") {var y_label_type = "Y_LABEL_NAME_IND_CO2"}
    this.yAxeLabel_amount = this.translateService.instant(`DASHBOARD.CHARTS.${ this.chart_id }.${ y_label_type }`);
    var unit_from_config = this.helper.getUnitIdFromFilterValue(this.value_type, this.unit[this.indicator]);
    this.yAxeLabel_amount += this.translateService.instant(`UNITS.${ unit_from_config["translate_unit"] }`);
    // Define behavior of tooltip label for the chart
    this.options.tooltips.callbacks.label = (item, data) => {
      let unit;
      if (this.value_type == 'pourcent'){
        unit = "%";
      }
      else {
        if (data.datasets[item.datasetIndex]['yAxisID']=='ind'){
          if(this.indicator == "energy"){unit = "kWh"} else if (this.indicator == "co2"){unit = "tCO2"}
          // this.translateService.instant("UNITS....)"
        }
        if(unit_from_config['translate_unit'] == "energy.inhabitant_kwh" || unit_from_config['translate_unit']  == "ghg.inhabitant_ton"){unit += "/ hab."}
      }
      return [`${data.datasets[item.datasetIndex].label} :`, `${this.helper.getThousandSeparatorFromNumber(item.yLabel)} `
      + unit]
    }

    const ctx = this.canvas.nativeElement.getContext('2d');
    this.options.scales.yAxes[0]['id'] = 'ind';
    this.options.legend.display = true;
    this.options.maintainAspectRatio = true;
    this.options.scales.yAxes[0]['scaleLabel'] = {
        display: true,
        labelString: this.yAxeLabel_amount,
        fontColor: 'black',
        fontSize: 14
    };
    this.chart = new Chart(ctx, {
      type: this.type,
      data: this.publicBdgData,
      options: this.options,
    });
  }

  resetChart() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = void 0;
    }
  }

  getSampleData(){
    let publicBdgChartLabels = [2015, 2016, 2017, 2018, 2019, 2020];
    let gas = [10, 15, 20, 25, 30, 25];
    let oil = [10, 15, 20, 25, 30, 25];
    let wood = [10, 15, 20, 25, 30, 25];

    var sampleData = {
      labels: publicBdgChartLabels,
    }

    this.publicBdgDatasets = [
      {
        label: 'Gaz',
        data: gas,
        stack: "public_bdg",
        backgroundColor: 'rgba(51, 51, 0, 0.3)',
        borderColor: 'rgba(51, 51, 0)',
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'mwh'
      },
      {
        label: 'Oil',
        data: oil,
        stack: "public_bdg",
        backgroundColor: 'rgba(151, 151, 0, 0.3)',
        borderColor: 'rgba(51, 51, 0)',
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'mwh'
      },
      {
        label: 'Wood',
        data: wood,
        stack: "public_bdg",
        backgroundColor: 'rgba(251, 251, 0, 0.3)',
        borderColor: 'rgba(51, 51, 0)',
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'mwh'
      }
    ];

    sampleData['datasets'] = this.publicBdgDatasets;
    return sampleData;
  }
}