import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/shared/helper';
import { Logger } from 'app/shared/services/logger.service';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-renewable-prod-chart',
  templateUrl: './renewable-prod-chart.component.html',
  styleUrls: ['./renewable-prod-chart.component.css']
})

export class RenewableProdChartComponent implements OnInit {
  @ViewChild('renProdChart') canvas;
  @Input() type;
  @Input() chart_id;
  @Input() options;
  @Input() indicator;
  @Input() sample_data;
  @Input() show_network_mix
  @Input() chartData;
  @Input() value_type;
  @Input() unit;

  public renewableProdData: any;
  private chart: Chart;
  private renewableProdDatasets;
  private yAxeLabel_main_ind: string;
  
  constructor(private helper: Helper, private translateService: TranslateService) { }

  ngOnInit() {
  }

  ngOnChanges(){
    this.createRenewableProdChart();
  }

  getTranslatedLabel(labelToTranslate) {
    return this.translateService.instant('DATA.' + labelToTranslate);
  }

  createRenewableProdChart(){
    this.resetChart();
    // For now only data for energy and not CO2
    if (this.indicator == 'co2'){
      this.indicator = 'energy';
    }
    var unit_from_config = this.helper.getUnitIdFromFilterValue(this.value_type, this.unit[this.indicator]);
    if (this.sample_data){
      this.renewableProdData = this.getSampleData();
    }
    else {
      if (this.chartData) {
        this.renewableProdData = {labels : this.chartData["labels"]};
        this.renewableProdData['datasets'] = this.chartData["datasets"];
        var i;
        for (i = 0; i < this.renewableProdData['datasets'].length; i++) {
          this.renewableProdData['datasets'][i]['label'] = this.getTranslatedLabel(this.renewableProdData['datasets'][i]['label']);
          if (this.value_type != 'pourcent' && this.indicator == 'energy'){
            var j;
            for (j = 0; j< this.renewableProdData['datasets'][i]['data'].length; j++){
              this.renewableProdData['datasets'][i]['data'][j] *= 1000;
            } 
          }
        }
      }
    }

    // DEFINE Y LABEL WITH UNIT
    var y_label_type = "Y_LABEL_NAME";
    this.yAxeLabel_main_ind = this.translateService.instant(`DASHBOARD.CHARTS.${ this.chart_id }.${ y_label_type }`);
    
    this.yAxeLabel_main_ind += this.translateService.instant(`UNITS.${ unit_from_config["translate_unit"] }`);
    // this.options['scales']['yAxes'][0]['scaleLabel']['labelString'] = this.yAxeLabel_main_ind;

    // Define behavior of tooltip for the chart
    this.options.tooltips.callbacks.label = (item, data) => {
      let unit;
      let translate_var = this.value_type
      unit = this.translateService.instant(`UNITS.${ unit_from_config["translate_unit"] }`);  
      return [`${data.datasets[item.datasetIndex].label} : ${this.helper.getThousandSeparatorFromNumber(item.yLabel)} `
      + unit]
    }

    const ctx = this.canvas.nativeElement.getContext('2d');
    this.options.scales = {
      yAxes: [{
        stacked: true,
        ticks:{
          callback: value => {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
          }
        },
        display: true,
        id: "main_ind",
        scaleLabel: {
          display: true,
          fontColor: 'black',
          fontSize: 14,
          labelString: this.yAxeLabel_main_ind
        }
      }]
    }
    this.options.legend.display = true;
    this.options.maintainAspectRatio = true;
    this.chart = new Chart(ctx, {
      type: this.type,
      data: this.renewableProdData,
      options: this.options,
    });
  }

  resetChart() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = void 0;
    }
  }

  getSampleData(){
    let renewableProdChartLabels = [2015, 2016, 2017, 2018, 2019, 2020];
    let gas = [10, 15, 20, 25, 30, 25];
    let oil = [10, 15, 20, 25, 30, 25];
    let wood = [10, 15, 20, 25, 30, 25];

    var sampleData = {
      labels: renewableProdChartLabels,
    }

    this.renewableProdDatasets = [
      {
        label: 'Gaz',
        data: gas,
        stack: "public_bdg",
        backgroundColor: 'rgba(51, 51, 0, 0.3)',
        borderColor: 'rgba(51, 51, 0)',
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'main_ind'
      },
      {
        label: 'Oil',
        data: oil,
        stack: "public_bdg",
        backgroundColor: 'rgba(151, 151, 0, 0.3)',
        borderColor: 'rgba(51, 51, 0)',
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'main_ind'
      },
      {
        label: 'Wood',
        data: wood,
        stack: "public_bdg",
        backgroundColor: 'rgba(251, 251, 0, 0.3)',
        borderColor: 'rgba(51, 51, 0)',
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'main_ind'
      }
    ];

    sampleData['datasets'] = this.renewableProdDatasets;
    return sampleData;
  }
}
