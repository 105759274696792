import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Chart } from "chart.js";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-pv-target-value-chart',
  templateUrl: './pv-target-value-chart.component.html',
  styleUrls: ['./pv-target-value-chart.component.css']
})
export class PvTargetValueChartComponent implements OnInit {
  @ViewChild("chartPvTargetValue") canvas;
  @Input() chartData;
  @Input() chartID;

  private chart: Chart;
  private options;
  private yAxes;
  private leftYAxisLabel;

  constructor(private translateService: TranslateService) { }

  ngOnInit() {
  }

  ngOnChanges() {
    // create or update chart only when data is provided
    if (this.chartData !== undefined) {
      this.updatechartDataWithTranslation();
      this.createChart();
    }
  }

  updatechartDataWithTranslation() {
    // updates the chart datasets labels with the string provided by the translate service
    this.chartData.datasets.forEach((dataset, index: number): void => {
      let translatedLabel = this.translateService.instant(
        "DATA." + dataset.label
      );
      this.chartData.datasets[index].label = translatedLabel;
    });
  }
  
  createChart() {
    const ctx = this.canvas.nativeElement.getContext("2d");
    this.resetChart();
    this.setupYAxes();
    this.setupOptions();
    this.chartData.datasets[4]['backgroundColor'] = this.createDiagonalPattern(this.chartData.datasets[4]['backgroundColor'], 'right');
    this.chartData.datasets[5]['backgroundColor'] = this.createDiagonalPattern(this.chartData.datasets[5]['backgroundColor'], 'left');
    this.chart = new Chart(ctx, {
      type: "bar",
      data: this.chartData,
      options: this.options
    });
  }

  setupYAxes() {
    this.getYAxesLabels();
    this.yAxes = [
      {
        id: "left",
        position: "left",
        type: "linear",
        scaleLabel: {
          display: true,
          labelString: this.leftYAxisLabel,
          fontColor: "black",
          fontSize: 14
        },
        ticks: {
          beginAtZero: true
        },
        stacked: true
      }
    ];
  }

  getYAxesLabels() {
    // axis label is simply the provided name in translate file
    let name;

    // surface axis
    name = this.translateService.instant(
      "DASHBOARD.CHARTS." + this.chartID + ".LEFT_Y_LABEL_NAME"
    );
    this.leftYAxisLabel = name;
  }

  setupOptions() {
    this.options = {
      responsive: true,
      scales: {
        yAxes: this.yAxes
      },
      tooltips: {
        callbacks: {
          label: this.tooltipsLabelCallback
        }
      },
    };
  }

  createDiagonalPattern(color = 'black', orientation='right') {
    // create a 10x10 px canvas for the pattern's base shape
    let shape = document.createElement('canvas')
    shape.width = 10
    shape.height = 10
    // get the context for drawing
    let c = shape.getContext('2d')
    if (orientation=='right'){
      // draw 1st line of the shape 
      c.strokeStyle = color
      c.beginPath()
      c.moveTo(2, 0)
      c.lineTo(0, 2)
      c.stroke()
      // draw 2nd line of the shape 
      c.beginPath()
      c.moveTo(10, 2)
      c.lineTo(2, 10)
      c.stroke()
    }
    else {
      // draw 1st line of the shape 
      c.strokeStyle = color
      c.beginPath()
      c.moveTo(2, 0)
      c.lineTo(10, 8)
      c.stroke()
      // draw 2nd line of the shape 
      c.beginPath()
      c.moveTo(0, 8)
      c.lineTo(2, 10)
      c.stroke()
    }
    // create the pattern from the shape
    return c.createPattern(shape, 'repeat')
  }

  tooltipsLabelCallback = (tooltipItem, data) => {
    let dataLabel = data.datasets[tooltipItem.datasetIndex].label + " :";
    let dataValue = tooltipItem.value;

    // add unit to ratio items
    if (data.datasets[tooltipItem.datasetIndex].physical_quantity == "ratio"){
      dataValue = Math.round(dataValue) + this.translateService.instant("UNITS.percentage.pct");
    }

    return [dataLabel + " " + dataValue];
  };


  resetChart() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = void 0;
    }
  }
}
