import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input, ViewChild, OnChanges, AfterViewInit } from '@angular/core';
import { Helper } from './../../../shared/helper';
import { Logger } from 'app/shared';

@Component({
  selector: 'htm-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.css']
})
export class LegendComponent implements OnInit {
  @Input() labels;
  @Input() bakcgroundColors;

  constructor(private logger: Logger, private helper: Helper) { }

  ngOnInit() {
    this.logger.log('LegendComponent/ngOnInit()');
  }
  getBusinessName(id) {
    return id;
  }
}
