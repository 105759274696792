import { defaultIdDisplayed } from "./../../shared/data";
import { TranslateService } from "@ngx-translate/core";
import { Helper } from "./../../shared/helper";
import { Component, OnInit, Input } from "@angular/core";
import { FilterOptionService } from "app/features/filter-options/filter-options.service";
import { ToasterService } from "app/shared";
import { City } from "app/features/filter-options/city";
import { OnChanges } from "@angular/core/src/metadata/lifecycle_hooks";
import { DataInteraction, Year } from "app/shared/data-interaction";
import * as uikit from "uikit";
import { DataCollectionService } from "app/shared/services/data-collection.service";

@Component({
  selector: "app-filter-options",
  templateUrl: "./filter-options.component.html",
  styleUrls: ["./filter-options.component.scss"]
})
export class FilterOptionsComponent implements OnInit, OnChanges {
  @Input("expanded") expanded;
  @Input() isDashboard;
  @Input() listOfFilterValuesToDisplay: {};
  @Input() ascYearsOPtion;

  private _cities: City[] = [];
  years: Year[] = [];

  private _buttonsValues;
  private _buttonsValuesSimple;
  private _buttonsIndicators;
  private _buttonsEnergy;
  private _buttonsEnergyServForBdg;
  private _buttonsEnergyType;
  private _buttonsPerimeter;
  private _buttonsNetworksInd;
  private _buttonsVehicleFuels;
  private _buttonsVehicleTypes;
  private _buttonsActionCategory;
  private _buttonsSiaAssignment;
  private _buttonsEnergeticAgent;
  private _selectedCity;
  selectedYear;
  private _multiCity;

  constructor(
    private dataCollectionService: DataCollectionService,
    private filterOptionsService: FilterOptionService,
    private toasterService: ToasterService,
    private helper: Helper,
    private translateService: TranslateService
  ) {}

  get selectedCity() {
    return this._selectedCity;
  }
  get cities() {
    return this._cities;
  }
  get buttonsValues() {
    return this._buttonsValues;
  }
  get buttonsValuesSimple() {
    return this._buttonsValuesSimple;
  }
  get buttonsIndicators() {
    return this._buttonsIndicators;
  }
  get buttonsEnergy() {
    return this._buttonsEnergy;
  }
  get buttonsEnergyServForBdg() {
    return this._buttonsEnergyServForBdg;
  }
  get buttonsEnergyType() {
    return this._buttonsEnergyType;
  }
  get buttonsPerimeter() {
    return this._buttonsPerimeter;
  }
  get buttonsNetworkInd() {
    return this._buttonsNetworksInd;
  }
  get buttonsVehicleFuels() {
    return this._buttonsVehicleFuels;
  }
  get buttonsVehicleTypes() {
    return this._buttonsVehicleTypes;
  }
  get buttonsActionCategory() {
    return this._buttonsActionCategory;
  }
  get buttonsSiaAssignment() {
    return this._buttonsSiaAssignment;
  }
  get buttonsEnergeticAgent() {
    return this._buttonsEnergeticAgent;
  }
  set selectedCity(s) {
    this._selectedCity = s;
  }

  ngOnInit() {
    this.dataCollectionService.getConfigFile().then(data => {
      this._multiCity = data.multiCity;
    });
    this.initFilters();
  }

  ngOnChanges(changes) {
    if (
      !!changes.isDashboard &&
      changes.isDashboard.firstChange &&
      this.isDashboard === true
    ) {
      this.filterOptionsService.setAllCharts();
      this.filterOptionsService.setFilters();
    }
  }
  initFilters() {
    this.filterOptionsService
      .getCities()
      .then((res: any) => {
        this._cities = this.filterOptionsService.setCities(res);
      })
      .then(() => {
        this.selectedYear = this.years[0];
        this._selectedCity = this.cities.filter(
          x => x.objectId === defaultIdDisplayed
        )[0];
        this._buttonsValues = this.filterOptionsService.getButtonsValueType();
        this._buttonsIndicators = this.filterOptionsService.getButtonsIndicator();
        this._buttonsEnergy = this.filterOptionsService.getButtonsEnergyService();
        this._buttonsEnergyServForBdg = this.filterOptionsService.getButtonsEnergyServiceForBdg();
        this._buttonsEnergyType = this.filterOptionsService.getButtonsEnergyType();
        this._buttonsPerimeter = this.filterOptionsService.getButtonsPerimeter();
        this._buttonsNetworksInd = this.filterOptionsService.getButtonsNetworksInd();
        this._buttonsVehicleFuels = this.filterOptionsService.getButtonsVehicleFuel();
        this._buttonsVehicleTypes = this.filterOptionsService.getButtonsVehicleType();
        this._buttonsActionCategory = this.filterOptionsService.getButtonsActionCategory();
        this._buttonsSiaAssignment = this.filterOptionsService.getButtonsSiaAssignment();
        this._buttonsEnergeticAgent = this.filterOptionsService.getButtonsEnergeticAgent();
      })
      .then(() => {
        this.filterOptionsService
          .getYears()
          .then(data => {
            var year_list = data["results"];
            // Filter years which are not selectable because of future projections or objectives
            this.years = year_list.filter(function(value, index, arr) {
              return parseInt(value["name"]) < 2030;
            });
            if (this.ascYearsOPtion) {
              var idOfSlecYear = 0;
            } else {
              var idOfSlecYear = this.years.length - 1;
            }
            this.selectedYear = this.years[idOfSlecYear];
            this.filterOptionsService.setYear(this.selectedYear, false);
            this.filterOptionsService.setDefaultFilterValues(
              this.listOfFilterValuesToDisplay,
              false
            );
          })
          .then(() => {
            this.onCitySelect(false);
          })
          .then(() => {
            // update chart and filter data globally
            this.filterOptionsService.setAllCharts();
            this.filterOptionsService.setFilters();
          });
      });
  }

  isButtonDisplayed(filterId, buttonId) {
    let isDisplayed = false;
    const valuesToDisplay = this.listOfFilterValuesToDisplay[filterId];
    // Check that values to display for the requested filter is defined
    // for now the test is needed because the config file is not yet updated for all filters.
    if (valuesToDisplay) {
      if (Object.keys(valuesToDisplay).includes(buttonId)) {
        isDisplayed = true;
      }
    }
    return isDisplayed;
  }

  isFilterDisplayed(filterId) {
    let isDisplayed = false;
    const filtersToDisplay = Object.keys(this.listOfFilterValuesToDisplay);
    // Check that values to display for the requested filter is defined
    // for now the test is needed because the config file is not yet updated for all filters.
    if (filtersToDisplay.includes(filterId)) {
      isDisplayed = true;
    }
    return isDisplayed;
  }

  // SETTER
  setValueType(id) {
    this.filterOptionsService.setValueType(id);
  }
  setIndicator(id) {
    this.filterOptionsService.setIndicator(id);
  }
  setPerimeter(id) {
    this.filterOptionsService.setPerimeter(id);
  }
  setNetworksInd(id) {
    this.filterOptionsService.setNetworksInd(id);
  }
  setVehicleFuels(id) {
    this.filterOptionsService.setVehicleFuels(id);
  }
  setVehicleTypes(id) {
    this.filterOptionsService.setVehicleTypes(id);
  }
  setActionCategory(id) {
    this.filterOptionsService.setActionCategory(id);
  }
  setSiaAssignment(id) {
    this.filterOptionsService.setSiaAssignment(id);
  }
  setEnergeticAgent(id) {
    this.filterOptionsService.setEnergeticAgent(id);
  }
  /* setTimelineValue(timeline) {
    this.filterOptionsService.setTimelineValue(timeline);
  } */
  onYearSelect() {
    this.filterOptionsService.setYear(this.selectedYear);
  }
  onCitySelect(update_global_data = true) {
    this.filterOptionsService.resetCitySelected();
    const selectedCity = this.filterOptionsService.getCity(
      this.selectedCity.objectId
    );
    selectedCity.selected = true;
    selectedCity.toCompare = false;
    if (update_global_data) {
      this.filterOptionsService.setAllCharts();
      this.filterOptionsService.dataFiltersNext();
    }
  }
  getTooltipTranslated(id, translateId) {
    uikit
      .tooltip("#" + id, {
        title: this.translateService.instant(translateId),
        pos: "right"
      })
      .show();
  }
  selectToCompare(city) {
    city.toCompare = !city.toCompare;
    this.filterOptionsService.selectToCompare(city);
  }

  setEnergyService(button) {
    this.filterOptionsService.setEnergyService(button);
  }

  setEnergyServiceBdg(button) {
    this.filterOptionsService.setEnergyServiceBdg(button);
  }

  setEnergyType(button) {
    this.filterOptionsService.setEnergyType(button);
  }
}
