import { Chart } from 'chart.js';
import { ThounsandSeparatorPipe } from 'app/shared/pipes/thounsand-separator.pipe';

export const durationAnimation = 500;
export const animationType = 'linear'
export const apiChartEndpoint = '/charts/'
export const apiStrategyChartEndpoint = '/strategy_charts/'
export const chartType = {
  piechart: 'doughnut',
  bar: 'bar',
  line: 'line',
  area: 'area'
};

export const chartOptionsBarChart = {
  legend: {
    display: false
  },
  animation: {
    hover: { mode: false },
    duration: durationAnimation,
    easing: animationType
  },
  responsive: true,
  maintainAspectRatio: false,
  
  scales: {
    xAxes: [{
      stacked: true,
      offset: true,
      ticks: {
        padding: 10,
      }
    }],
    yAxes: [{
      stacked: true,
      ticks: {
      callback: value => {
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
        }
      },
      scaleLabel: {
        display: true,
        fontColor: 'black',
        fontSize: 14
      }
    }]
  },
  tooltips: {
    callbacks: {
      label:{}
    }
  }
}

export const chartOptionsMobilityChart = {
  legend: {
    display: true
  },
  animation: {
    hover: { mode: false },
    duration: durationAnimation,
    easing: animationType
  },
  responsive: true,
  // maintainAspectRatio: false,
  
  scales: {
    // this.xAxes_def = [{
    //   stacked: false,
    //   display: true,
    //   id: "x_def",
    //   ticks: {
    //     font: 'Arial',
    //     fontColor: 'black',
    //     fontSize: 12
    //   }
    // }]
    yAxes: [{
      stacked: false,
      display: true,
      id: "nb",
      scaleLabel: {
        display: false,
        fontColor: 'black',
        fontSize: 13
      },
      ticks: {
        count: 10,
        fontColor: 'black',
        fontSize: 13,
        beginAtZero: true,
        callback: value => {
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
        }
      }
    },
    {
      stacked: false,
      display: true,
      id: "ind",
      position: "right",
      type:'linear',
      scaleLabel: {
        display: false,
        fontColor: 'black',
        fontSize: 13
      },
      ticks: {
        count: 10,
        fontColor: 'black',
        fontSize: 13,
        beginAtZero: true,
        callback: value => {
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
        }
      },
      gridLines: {
        display: false
      }
    }]
  },
  tooltips: {
    callbacks: {
      label:{}
    }
  }
}

export const chartOptionsPiechart = {
  animation: {
    duration: durationAnimation,
    easing: animationType
  },

  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false
  },
  tooltips: {
    caretSize:0,
    callbacks: {
      label:{}
    }
  }
}

export const chartOptionsAreachart = {
  animation: {
    duration: durationAnimation,
    easing: animationType
  },
  scaleShowVerticalLines: false,
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: true
  },
  tooltips: {
    caretSize:0,
    callbacks: {
      label:{}
    }
  }
}

export const chartOptionsLinechart = {
  animation: {
    duration: durationAnimation,
    easing: animationType
  },
  scales: {
    yAxes: [{
      stacked: true,
      display: true,
      id: "main_ind",
      scaleLabel: {
        display: true,
        fontColor: 'black',
        fontSize: 13
      },
      ticks: {
        count: 10,
        fontColor: 'black',
        fontSize: 13,
        beginAtZero: true,
        callback: value => {
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
        }
      }
    }]
  },
  scaleShowVerticalLines: false,
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: true
  },
  tooltips: {
    caretSize:0,
    callbacks: {
      label:{}
    }
  }
}
