import {
  Component,
  OnInit,
  ViewChild,
  AfterContentInit,
  Input,
  ViewEncapsulation
} from "@angular/core";
import { SidePanelService } from "../../features/side-panel/side-panel.service";
import { FilterOptionService } from "app/features/filter-options/filter-options.service";
import { DataInteraction } from "app/shared/data-interaction";
import { KpiComponent } from "app/features/kpi/kpi.component";
import { ChartsComponent } from "app/features/charts/charts.component";
import { DashSelectionService } from "app/shared/services/dash-selection.service";
import { Subject, Observable } from "rxjs";
import { DataCollectionService } from "app/shared/services/data-collection.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit{
  selectedDashboard$: Observable<String>;
  availableDashboards$: Observable<String[]>;
  private _dataFilters: DataInteraction;
  private _indexOfSelectedDashboard;
  private dashboard_id: String;
  private configData;

  constructor(
    private dataCollectionService: DataCollectionService,
    private panelService: SidePanelService,
    private filterOptionService: FilterOptionService,
    private dashSelectionService: DashSelectionService
  ) {
    this.availableDashboards$ = dashSelectionService.getAvailableDashboard();
    this.selectedDashboard$ = dashSelectionService.getSelectedDashboard();
    this.filterOptionService.getDataFilters().subscribe(data => {
      if (data) {
        this._dataFilters = Object.assign({}, data);
      }
    });
  }

  ngOnInit(): void {
    // read configuration file
    this.dataCollectionService.getConfigFile().then(configData => {
      // save configuration file in component
      this.configData = configData;
    });
  }

  get dataFilters() {
    return this._dataFilters;
  }

  onTabChanged(event) {
    this._indexOfSelectedDashboard = event.index;
    this.dashboard_id = this.dashboardLabelToId(event.tab.textLabel);
    this.dashSelectionService.setNewDashboard(this.dashboard_id);
  }

  dashboardLabelToId(labelToTranslate) {
    var pairValues = {
      Publique: "public",
      Général: "public",
      "Energie des bâtiments": "energie_batiments",
      Chaleur: "heat",
      Electricité: "electricity",
      "Réseaux thermiques": "dhn",
      "Production renouvelable": "renewable_production",
      Réseaux: "networks",
      Mobilité: "mobility",
      Subventions: "subsidies",
      "Actions sur le territoire": "actions",
      "Bâtiments communaux": "patrimony",
      Démo: "demo"
    };
    var idOfSelectedDashboard = pairValues[labelToTranslate];
    return idOfSelectedDashboard;
  }
}
