import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/shared/helper';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-actios-charts',
  templateUrl: './actios-charts.component.html',
  styleUrls: ['./actios-charts.component.css']
})
export class ActiosChartsComponent implements OnInit {
  @ViewChild('chartActions') canvas;
  @Input() type;
  @Input() chart_id;
  @Input() options;
  @Input() indicator;
  @Input() show_network_mix
  @Input() chartData
  @Input() sample_data;

  public actionsChartOptions: { responsive: boolean; };
  public actionsChartLabels: any;
  public actionsChartData: any;
  private areaChartDatasets;
  private chart: Chart;
  private yAxes_def;
  private xAxes_def;
  private sampleData;
  
  constructor(private helper: Helper, private translateService: TranslateService) { }
  
  ngOnInit() {
  }
  
  ngOnChanges(changes){
    if (!this.helper.isNullOrUndefined(changes.chartData) && !this.helper.isNullOrUndefined(changes.chartData.currentValue)) {
      this.createActionChart();
    }
  }

  getTranslatedLabel(labelToTranslate) {
    return this.translateService.instant('DATA.' + labelToTranslate)
  }
  
  createActionChart() {
    this.resetChart();
    this.actionsChartData = this.chartData;
    var i;
      for (i = 0; i < this.actionsChartData['datasets'].length; i++) {
        this.actionsChartData['datasets'][i]['label'] = this.getTranslatedLabel(this.actionsChartData['datasets'][i]['label']);
        // this.areaChartData['datasets'][i]['label'] = "TEST";
      }
    this.actionsChartOptions = {
      responsive: true
    }
    const ctx = this.canvas.nativeElement.getContext('2d');
    this.xAxes_def = [{
      stacked: true,
      display: true,
      id: "x_def",
      ticks: {
        fontColor: 'black',
        fontSize: 13
      }
    }]
    this.yAxes_def = [{
      stacked: true,
      display: true,
      id: "nombre d'actions",
      scaleLabel: {
        display: true,
        labelString: "Nombre d'actions",
        fontColor: 'black',
        fontSize: 13
        },
      ticks: {
        fontColor: 'black',
        fontSize: 12
      }
    }]
    this.chart = new Chart(ctx, {
      type: this.type,
      data: this.actionsChartData,

      options: {
        responsive: true,
        scales: {
          yAxes: this.yAxes_def
        }
      }
    });
    
  }
  
  getSampleData() {
    let networksChartLabels = [2015, 2016, 2017, 2018, 2019, 2020];
    let opaleData = [96000, 110000, 105000, 98000, 92000, 90000];
    let topazeData = [0, 0, 4500, 5475, 6450, 12000];
    var ambreData = [0, 2000, 0, 10000, 50000, 50000];
    
    var sampleData = {
      labels: networksChartLabels,
    }
    if (!this.show_network_mix) {
      this.areaChartDatasets = [
        {
          label: 'Opale',
          data: opaleData,
          stack: "stack-elec",
          backgroundColor: 'rgba(50, 50, 50, 0.3)',
          borderColor: 'rgba(50, 50, 50)',
          borderWidth: 1,
          lineTension: 0,
          yAxisID: 'mwh'
        },
        {
          label: 'Topaze',
          data: topazeData,
          stack: "stack-elec",
          backgroundColor: 'rgba(164, 24, 211, 0.3)',
          borderColor: 'rgba(164, 24, 211)',
          borderWidth: 1,
          lineTension: 0,
          yAxisID: 'mwh'
        },
        {
          label: 'Ambre',
          data: ambreData,
          stack: "stack-elec",
          backgroundColor: 'rgba(0, 150, 255, 0.3)',
          borderColor: 'rgba(0, 150, 255)',
          borderWidth: 1,
          lineTension: 0,
          yAxisID: 'mwh'
        }
      ];
    sampleData['datasets']= this.areaChartDatasets
    return sampleData;
    }
  }

  resetChart() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = void 0;
    }
  }

  private defineIfShowgasDebt(){
  }

}
