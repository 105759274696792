import { Observable } from 'rxjs/Rx';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from './../../../shared/helper';
import { Component, OnInit, Input, ViewChild, OnChanges,  } from '@angular/core';
import { Chart } from 'chart.js';
import { Logger } from 'app/shared';
import { MultiLegendComponent } from '../multi-legend/multi-legend.component';

@Component({
  selector: 'htm-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.css']
})
export class ChartComponent implements OnInit, OnChanges {

  @ViewChild('canvas') canvas;
  @ViewChild(MultiLegendComponent) multiLegendComponent: MultiLegendComponent;
  @Input() type;
  @Input() chart_id;
  @Input() options;
  @Input() chartData;
  @Input() unit;
  @Input() value_type;
  @Input() indicator;
  private chart: Chart;
  private yAxeLabel: string;
  private tot_ren_dougnhut;
  private unit_ren_doughnut: String;

  constructor(private logger: Logger, private helper: Helper, private translateService: TranslateService) { }

  ngOnInit() {
    this.logger.log('ChartComponent/ngOnInit()');
  }
  ngOnChanges(changes) {
    if (!this.helper.isNullOrUndefined(changes.chartData) && !this.helper.isNullOrUndefined(changes.chartData.currentValue)) {
      this.createChart();
    }
  }

  createChart() {
    this.resetChart();
    const ctx = this.canvas.nativeElement.getContext('2d');
    if (!this.helper.isNullOrUndefined(this.options)) {
      /* Remove label for dataset when only one city is displayed on the chart*/
      if (this.chartData["labels"] && this.chartData["labels"].length==1){
        this.chartData["labels"] = [""];
      }
      /* Convert unit and values when needed for displaying like indicated in config file and 
      not from data in database*/
      var i;
      if (this.type == 'bar'){
        if (this.indicator == "energy"){var y_label_type = "Y_LABEL_NAME"} else if (this.indicator == "co2") {var y_label_type = "Y_LABEL_NAME_CO2"}
        this.yAxeLabel = this.translateService.instant(`DASHBOARD.CHARTS.${ this.chart_id }.${ y_label_type }`);
        var unit_from_config = this.helper.getUnitIdFromFilterValue(this.value_type, this.unit[this.indicator]);
        this.yAxeLabel += this.translateService.instant(`UNITS.${ unit_from_config["translate_unit"] }`);
        this.options['scales']['yAxes'][0]['scaleLabel']['labelString'] = this.yAxeLabel;
      }

      if (this.value_type == "pourcent"){
        var data_unit = "pct";
      }
      else if(this.value_type == "habitant"){
        var data_unit = `inhabitant_${this.chartData["datasets"][0]["unit"][0]}`;
        var unit_from_config = `inhabitant_${this.unit[this.indicator]}`;
      }
      else if(this.value_type == "absolute"){
        var data_unit = `${this.chartData["datasets"][0]["unit"][0]}`;
        var unit_from_config = `${this.unit[this.indicator]}`;
      }
      for (i=0; i<this.chartData["datasets"].length; i++){
        var j;
        for (j=0; j<this.chartData["datasets"][i]["unit"].length; j++){
          if (data_unit == "pct"){this.chartData["datasets"][i]["unit"][j] = this.helper.getUnitInfo(data_unit)["translate_unit"]}
          else {
            if (this.indicator == 'energy'){
              var temporary_factor = 1000
            }
            else {
              var temporary_factor = 1
            }
            let unit_to_display = this.helper.getUnitInfo(unit_from_config);
            this.chartData["datasets"][i]["data"][j] *= temporary_factor;
            this.chartData["datasets"][i]["unit"][j] = unit_to_display["translate_unit"];
            // [this.chartData["datasets"][i]["data"][j], this.chartData["datasets"][i]["unit"][j]] = this.helper.convertTooBigValue(this.chartData["datasets"][i]["data"][j], this.chartData["datasets"][i]["unit"][j]);
          }
        }
      }
      if (this.chartData['labels'].length > 1 && this.chartData['labels'].includes('')){
        this.chartData['labels'] = this.chartData['labels'].filter(e => {
          return e !== '';
        });
      }
      this.options.tooltips.callbacks.label = (item, data) => {
        let unit;
        let result;
        let datalabel;
        let translate_var = this.value_type
        if (this.type === 'bar') {
          datalabel = data.datasets[item.datasetIndex].label;
          result = item.yLabel
          unit = data.datasets[item.datasetIndex].unit[item.index];
          [result, unit] = this.helper.convertTooBigValue(item.yLabel, data.datasets[item.datasetIndex].unit[item.index]);
        } else if (this.type === 'doughnut'){
          datalabel = data.labels[item.index];
          [result, unit] = this.helper.convertTooBigValue(data.datasets[0].data[item.index], data.datasets[item.datasetIndex].unit[item.index]);
        } else {
          datalabel = data.labels[item.index];
          result = data.datasets[0].data[item.index];
          unit = data.datasets[0].unit[item.index];
        }
        translate_var = unit;
        
       /*  else {
          translate_var = unit + '.energy_absolute';
        } */
        return [`${this.translateService.instant('DATA.' + datalabel)} :`, `${this.helper.getThousandSeparatorFromNumber(result)} `
        + this.translateService.instant('UNITS.' + translate_var )]
      }
      this.options.animation.onComplete = () => {
        if (this.multiLegendComponent) {
          this.multiLegendComponent.update();
        }
      }
    }
    this.chart = new Chart(ctx, {
      type: this.type,
      data: this.chartData,
      options: this.options
    });

  }

  resetChart() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = void 0;
    }
  }
}
