import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/shared/helper';
import { Logger } from 'app/shared/services/logger.service';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-subsidies-chart',
  templateUrl: './subsidies-chart.component.html',
  styleUrls: ['./subsidies-chart.component.css']
})
export class SubsidiesChartComponent implements OnInit {
  @ViewChild('chartSubsidies') canvas;
  @Input() type;
  @Input() chart_id;
  @Input() options;
  @Input() indicator;
  @Input() sample_data;
  @Input() chartData;
  @Input() value_type;
  @Input() unit;
  @Input() show_network_mix;

  private yAxeLabel: String;
  //To change or improve
  private chart: Chart;
  public subsidiesData: any;
  private display_option = "stacked";
  private areaChartDatasets;

  constructor(private helper: Helper, private translateService: TranslateService) { }

  ngOnInit() {
  }

  ngOnChanges(){
    this.createSubsidiesChart();
  }

  getTranslatedLabel(labelToTranslate) {
    return this.translateService.instant('DATA.' + labelToTranslate);
  }

  createSubsidiesChart() {
    this.resetChart();
    if (this.sample_data){
      this.subsidiesData = this.getSampleData();
    }
    else {
      if (this.chartData) {
        this.yAxeLabel = this.translateService.instant(`DASHBOARD.CHARTS.${ this.chart_id }.Y_LABEL_NAME`);
        this.subsidiesData = {labels : this.chartData['subsidies_labels']};
        this.subsidiesData['datasets'] = this.chartData['subsidies_datasets'];
        var unit_from_config = this.helper.getUnitIdFromFilterValue(this.value_type, this.unit['money']);
        this.yAxeLabel += this.translateService.instant(`UNITS.${ unit_from_config["translate_unit"] }`); 
        this.options['scales']['yAxes'][0]['scaleLabel']['labelString'] = this.yAxeLabel;
        var i;
        for (i = 0; i < this.subsidiesData['datasets'].length; i++) {
          this.subsidiesData['datasets'][i]['label'] = this.getTranslatedLabel(this.subsidiesData['datasets'][i]['label']);
        }
      }
    }
    // Define behavior of tooltip label for the chart
    this.options.tooltips.callbacks.label = (item, data) => {
      let unit;
      unit = this.translateService.instant(`UNITS.${ unit_from_config["translate_unit"] }`);
      return [`${data.datasets[item.datasetIndex].label} : ${this.helper.getThousandSeparatorFromNumber(item.yLabel)} `
      + unit]
    }
    this.options.responsive = true;
    const ctx = this.canvas.nativeElement.getContext('2d');
    /* if (this.display_option == "stacked"){
      this.options.scales.yAxes[0].stacked = true;
    } */
    this.options['maintainAspectRatio'] = true;
    this.options.legend.display = true;
    this.chart = new Chart(ctx, {
      type: this.type,
      data: this.subsidiesData,
      options: this.options
    });

  }

  resetChart() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = void 0;
    }
  }

  getSampleData() {
    let networksChartLabels = [2017, 2018, 2019, 2020];
    let gazData = [104490000, 98973204, 92026299, 90000000];
    let dhnData = [4500000, 5475000, 6450000, 8250000];
    var dcnData = [0, 10000000, 50000000, 50000000];

    var sampleData = {
      labels: networksChartLabels,
    }
    if (!this.show_network_mix) {
      this.areaChartDatasets = [
        {
          label: 'Réseau de gaz',
          data: gazData,
          stack: "gaz",
          backgroundColor: 'rgba(50, 50, 50, 0.3)',
          borderColor: 'rgba(50, 50, 50)',
          borderWidth: 1,
          lineTension: 0,
          yAxisID: 'mwh'
        },
        {
          label: 'Réseaux CAD',
          data: dhnData,
          stack: "dhn",
          backgroundColor: 'rgba(164, 24, 211, 0.3)',
          borderColor: 'rgba(164, 24, 211)',
          borderWidth: 1,
          lineTension: 0,
          yAxisID: 'mwh'
        }
      ];
    }
    else if (this.show_network_mix) {
      this.areaChartDatasets = [
      {
        label: "Gaz - Gaz naturel",
        data: gazData.map(function(x) { return x * 0.95; }),
        stack: "gaz",
        backgroundColor: 'rgba(50, 50, 50, 0.3)',
        borderColor: 'rgba(50, 50, 50)',
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'mwh'
      },
      {
        label: 'Gaz - Biogaz',
        data: gazData.map(function(x) { return x * 0.05; }),
        stack: "gaz",
        backgroundColor: 'rgba(204, 153, 51, 0.3)',
        borderColor: 'rgba(204, 153, 51)',
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'mwh'
      },
      {
        label: 'CAD - Bois',
        data: dhnData.map(function(x) { return x * 0.5; }),
        stack: "dhn",
        backgroundColor: 'rgba(153, 102, 51, 0.3)',
        borderColor: 'rgba(153, 102, 51)',
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'mwh'
      },
      {
        label: 'CAD - Gaz',
        data: dhnData.map(function(x) { return x * 0.2; }),
        stack: "dhn",
        backgroundColor: 'rgba(50, 50, 50, 0.3)',
        borderColor: 'rgba(50, 50, 50)',
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'mwh'
      },
      {
        label: 'CAD - PAC',
        data: dhnData.map(function(x) { return x * 0.3; }),
        stack: "dhn",
        backgroundColor: 'rgba(51, 204, 153, 0.3)',
        borderColor: 'rgba(51, 204, 153)',
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'mwh'
      },
      {
        label: 'FAD - Eau lac',
        data: dcnData.map(function(x) { return x * 0.9; }),
        stack: "dhn",
        backgroundColor: 'rgba(0, 153, 204, 0.3)',
        borderColor: 'rgba(0, 153, 204)',
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'mwh'
      },
      {
        label: 'FAD - Elec',
        data: dcnData.map(function(x) { return x * 0.1; }),
        stack: "dhn",
        backgroundColor: 'rgba(0, 51, 153, 0.3)',
        borderColor: 'rgba(0, 51, 153)',
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'mwh'
      },
      ];
    }
    sampleData['datasets']= this.areaChartDatasets
    return sampleData;
  }

}
