/**
 * Created by Albain on 20.12.17.
 */
import { Injectable } from '@angular/core';
import { APIService, ToasterService, Logger, LoaderService } from 'app/shared';
import { Http } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { Helper } from '../helper';
import { URLAPI } from '../const';


@Injectable()
export class DataCollectionService extends APIService {
    constructor(protected http: Http, protected logger: Logger, protected loaderService: LoaderService,
        protected toasterService: ToasterService, private helper: Helper) {
        super(http, logger, loaderService, toasterService);
    }

    getKPI(cityId, year_id, kpiList = ""): Observable<any> {
      var getKpiUrl = URLAPI + '/indicators/' + cityId + '/' + year_id;
      var i;
      getKpiUrl += `?kpis=${kpiList}`; 
      return super.GET(getKpiUrl)
    }

    getLastUpdateDate(): Observable<any> {
      return super.GET(URLAPI + '/lastupdate/')
    }

    getConfigFile(): Promise<any> {
      return super.GETPromise('/assets/config/config.json')
    }
    
    webframeExists() {
      return this.getConfigFile().then(data => {
        if (!this.helper.isNullOrUndefined(data.webframe) && data.webframe !== '' && this.helper.validURL(data.webframe)) {
          return true;
        } else {
          return false;
        }


      })
    }    
}
