import { URLAPI } from "../../shared/const";
import { defaultIdDisplayed } from "./../../shared/data";
import { Injectable, SimpleChanges } from "@angular/core";
import { Http } from "@angular/http";

import {
  APIService,
  Logger,
  LoaderService,
  ToasterService,
  defaultValueType,
  defaultIndicator,
  defaultEnergyService,
  defaultEnergyServiceBdg,
  defaultEnergyType,
  defaultNetworksInd,
  defaultPerimeter,
  defaultVehicleFuels,
  defaultVehicleTypes,
  defaultDashboardType,
  defaultTimeLine,
  defaultActionCategory,
  defaultSiaAssignment,
  defaultEnergeticAgent,
  buttonsValueType,
  buttonsIndicator,
  buttonsEnergyService,
  buttonsEnergyServiceForBdg,
  buttonsEnergyType,
  buttonsNetworksIndService,
  buttonsPerimeterService,
  buttonsVehicleFuelService,
  buttonsVehicleTypeService,
  buttonsActionCategory,
  buttonsSiaAssignment,
  buttonsEnergeticAgent,
  idChartMix,
  idChartRenewable,
  idChartLocal,
  idChartEvolution,
  idChartActual,
  idChartStrategy,
  idChartStrategyElec,
  idChartStrategyHeat,
  idChartNetworksGeneral,
  idChartStrategyBuilding,
  idChartStrategyMobility,
  idChartVechiculeFuelNb,
  idChartVechiculeTypeNb,
  idChartRenHeatDoughnut,
  idChartRenElecDoughnut,
  idChartRenMobilityDoughnut,
  idChartElecProductConsumption,
  idChartRenEnergyProd,
  idChartActions,
  idChartSubsidies,
  idChartS2000W,
  idChartPublicBdg,
  idChartHeaterSubstitution,
  idChartElecProd,
  idChartPvTarget,
  idPublicConsumptionByBuildingType,
  idPublicConsumptionByEnergeticAgent
} from "app/shared";
import { City } from "app/features/filter-options/city";
import { DataInteraction, Year } from "app/shared/data-interaction";
import { OnChanges } from "@angular/core/src/metadata/lifecycle_hooks";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { Button } from "selenium-webdriver";

@Injectable()
export class FilterOptionService extends APIService {
  private cities;
  private dataFilters: BehaviorSubject<DataInteraction> = new BehaviorSubject<
    DataInteraction
  >(null);
  private years: Year[]; // = [{ "name": "2018", "id": 1 }, { "name": "2019", "id": 2 }, { "name": "2020", "id": 3 }, { "name": "2050", "id": 18 }]
  private year: Year; //  = this.years[0];
  private charts: string[] = [
    idChartEvolution,
    idChartActual,
    idChartMix,
    idChartRenewable,
    idChartLocal,
    idChartStrategy,
    idChartStrategyElec,
    idChartStrategyHeat,
    idChartNetworksGeneral,
    idChartStrategyBuilding,
    idChartStrategyMobility,
    idChartElecProductConsumption,
    idChartActions,
    idChartSubsidies,
    idChartS2000W,
    idChartPublicBdg
  ];
  private buttonsValues = buttonsValueType;
  private buttonsIndicators = buttonsIndicator;
  private buttonsEnergy = buttonsEnergyService;
  private buttonsEnergyServiceForBdg = buttonsEnergyServiceForBdg;
  private buttonsEnergyType = buttonsEnergyType;
  private energy_services: string[] = defaultEnergyService;
  private energy_services_bdg: string[] = [];
  private energy_type: string[] = [];
  private buttonsNetworksInd = buttonsNetworksIndService;
  private networksInd: string = defaultNetworksInd;
  private buttonsPerimeter = buttonsPerimeterService;
  private perimeter: string = defaultPerimeter;
  private buttonsVehicleFuel = buttonsVehicleFuelService;
  private vehicleFuel: string[] = [];
  private buttonsVehicleType = buttonsVehicleTypeService;
  private vehicleType: string[] = [];
  private buttonsActionCategory = buttonsActionCategory;
  private actionCategory: string = defaultActionCategory;
  private buttonsSiaAssignment = buttonsSiaAssignment;
  private siaAssignment: string[] = [];
  private buttonsEnergeticAgent = buttonsEnergeticAgent;
  private energeticAgent: string[] = [];
  private value_type = defaultValueType;
  private indicator = defaultIndicator;

  constructor(
    protected http: Http,
    protected logger: Logger,
    protected loaderService: LoaderService,
    protected toasterService: ToasterService
  ) {
    super(http, logger, loaderService, toasterService);
  }

  getDataFilters(): BehaviorSubject<DataInteraction> {
    return this.dataFilters;
  }

  // SETTER
  selectToCompare(city) {
    this.setMainCharts();
    this.setFilters();
  }
  setYear(year, update_global_data = true) {
    this.year = year;
    if (update_global_data) {
      this.setAllCharts();
      this.setFilters();
    }
  }
  setValueType = (id, update_global_data = true) => {
    if (this.value_type != id) {
      this.buttonsValues.forEach(button => {
        button.selected = button.id == id;
      });
      this.value_type = id;
      if (update_global_data) {
        this.setAllCharts();
        this.setFilters();
      }
    }
  };
  setIndicator = (id, update_global_data = true) => {
    if (this.indicator != id) {
      this.buttonsIndicators.forEach(button => {
        button.selected = button.id == id;
      });
      this.indicator = id;
      if (update_global_data) {
        this.setAllCharts();
        this.setFilters();
      }
    }
  };
  /* setTimelineValue(timeline) {
        this.dataFilters.value.timeline = timeline;
        this.setFilters();
    } */
  setEnergyService = (button, update_global_data = true) => {
    if (!button.selected) {
      this.energy_services.push(button.id);
    } else {
      const index = this.energy_services.indexOf(button.id);
      if (index > -1) {
        this.energy_services.splice(index, 1);
      }
    }
    button.selected = !button.selected;
    if (update_global_data) {
      this.setAllCharts();
      this.setFilters();
    }
  };
  setEnergyServiceBdg = (button, update_global_data = true) => {
    if (!button.selected) {
      this.energy_services_bdg.push(button.id);
      button.selected = true;
    } else {
      const index = this.energy_services_bdg.indexOf(button.id);
      if (index > -1) {
        this.energy_services_bdg.splice(index, 1);
        button.selected = false;
      }
    }
    if (update_global_data) {
      this.setAllCharts();
      this.setFilters();
    }
  };
  setEnergyType = (button, update_global_data = true) => {
    if (!button.selected) {
      this.energy_type.push(button.id);
      button.selected = true;
    } else {
      const index = this.energy_type.indexOf(button.id);
      if (index > -1) {
        this.energy_type.splice(index, 1);
        button.selected = false;
      }
    }
    if (update_global_data) {
      this.setAllCharts();
      this.setFilters();
    }
  };
  setPerimeter = (id, update_global_data = true) => {
    if (this.perimeter != id) {
      this.buttonsPerimeter.forEach(button => {
        button.selected = button.id == id;
      });
      this.perimeter = id;
      if (update_global_data) {
        this.setAllCharts();
        this.setFilters();
      }
    }
  };
  setNetworksInd = (id, update_global_data = true) => {
    if (this.networksInd != id) {
      this.buttonsNetworksInd.forEach(button => {
        button.selected = button.id == id;
      });
      this.networksInd = id;
      if (update_global_data) {
        this.setAllCharts();
        this.setFilters();
      }
    }
  };
  setVehicleFuels = (button, update_global_data = true) => {
    if (!button.selected) {
      this.vehicleFuel.push(button.id);
      button.selected = true;
    } else {
      const index = this.vehicleFuel.indexOf(button.id);
      if (index > -1) {
        this.vehicleFuel.splice(index, 1);
        button.selected = false;
      }
    }
    if (update_global_data) {
      this.setAllCharts();
      this.setFilters();
    }
  };
  setVehicleTypes = (button, update_global_data = true) => {
    if (!button.selected) {
      this.vehicleType.push(button.id);
      button.selected = true;
    } else {
      const index = this.vehicleType.indexOf(button.id);
      if (index > -1) {
        this.vehicleType.splice(index, 1);
        button.selected = false;
      }
    }
    if (update_global_data) {
      this.setAllCharts();
      this.setFilters();
    }
  };
  setActionCategory = (id, update_global_data = true) => {
    if (this.actionCategory != id) {
      this.buttonsActionCategory.forEach(button => {
        button.selected = button.id == id;
      });
      this.actionCategory = id;
      if (update_global_data) {
        this.setAllCharts();
        this.setFilters();
      }
    }
  };
  setSiaAssignment = (button, update_global_data = true) => {
    if (!button.selected) {
      this.siaAssignment.push(button.id);
      button.selected = true;
    } else {
      const index = this.siaAssignment.indexOf(button.id);
      if (index > -1) {
        this.siaAssignment.splice(index, 1);
        button.selected = false;
      }
    }
    if (update_global_data) {
      this.setAllCharts();
      this.setFilters();
    }
  };
  setEnergeticAgent = (button, update_global_data = true) => {
    if (!button.selected) {
      this.energeticAgent.push(button.id);
      button.selected = true;
    } else {
      const index = this.energeticAgent.indexOf(button.id);
      if (index > -1) {
        this.energeticAgent.splice(index, 1);
        button.selected = false;
      }
    }
    if (update_global_data) {
      this.setAllCharts();
      this.setFilters();
    }
  };

  setMainCharts() {
    this.charts = [idChartEvolution, idChartActual];
  }
  setAllCharts() {
    this.charts = [
      idChartEvolution,
      idChartActual,
      idChartMix,
      idChartRenewable,
      idChartLocal,
      idChartStrategy,
      idChartStrategyElec,
      idChartStrategyHeat,
      idChartNetworksGeneral,
      idChartStrategyBuilding,
      idChartStrategyMobility,
      idChartVechiculeFuelNb,
      idChartVechiculeTypeNb,
      idChartRenHeatDoughnut,
      idChartRenElecDoughnut,
      idChartRenMobilityDoughnut,
      idChartElecProductConsumption,
      idChartRenEnergyProd,
      idChartActions,
      idChartSubsidies,
      idChartS2000W,
      idChartPublicBdg,
      idChartHeaterSubstitution,
      idChartElecProd,
      idChartPvTarget,
      idPublicConsumptionByBuildingType,
      idPublicConsumptionByEnergeticAgent
    ];
  }
  setFilters() {
    this.dataFilters.next({
      dashboard_type: defaultDashboardType,
      value_type: this.value_type,
      indicator: this.indicator,
      energy_service: this.energy_services,
      energy_service_bdg: this.energy_services_bdg,
      energy_type: this.energy_type,
      cities: this.cities,
      charts: this.charts,
      year: this.year,
      networksInd: this.networksInd,
      perimeter: this.perimeter,
      vehicleFuel: this.vehicleFuel,
      vehicleType: this.vehicleType,
      actionCategory: this.actionCategory,
      siaAssignment: this.siaAssignment,
      energeticAgent: this.energeticAgent
    });
  }

  setDefaultFilterValues(desiredFilterValues, update_global_data = true) {
    // make sure there are default values provided for the filters
    if (desiredFilterValues) {
      // for each filter set the default values
      const filterNames = Object.keys(desiredFilterValues);
      filterNames.forEach(filterName => {
        const currentFilterValues = desiredFilterValues[filterName];
        // make sure there are default values provided for this filter
        if (currentFilterValues) {
          // set the value for each button of the current filter
          const buttonNames = Object.keys(currentFilterValues);
          buttonNames.forEach(buttonName => {
            this.setButton(
              filterName,
              buttonName,
              currentFilterValues[buttonName],
              false
            );
          });
        }
      });
    }

    if (update_global_data) {
      this.setAllCharts();
      this.setFilters();
    }
  }

  /* 
  Set specific button to the desired value
  If the button is a radio button, it is only set if the desired value is true.
  Nothing happens if the desired value is false, because it is not possible to infer which button should be selected.
   */
  setButton(filterName, buttonId, buttonValue, update_global_data = true) {
    enum FilterType {
      Xor, // radio button
      Or // checklist
    }

    let buttonSetter;
    let buttonsGetter;
    let filterType: FilterType;

    // Process button differently according to filter
    switch (filterName) {
      case "compareCitiesFromRegion":
        //TODO
        break;
      case "yearSelector":
        //TODO
        break;

      case "typeOfValue":
        filterType = FilterType.Xor;
        buttonSetter = this.setValueType;
        break;

      case "typeOfIndicator":
        filterType = FilterType.Xor;
        buttonSetter = this.setIndicator;
        break;

      case "energyService":
        filterType = FilterType.Or;
        buttonSetter = this.setEnergyService;
        buttonsGetter = this.getButtonsEnergyService;
        break;

      case "energyServiceForBuildings":
        filterType = FilterType.Or;
        buttonSetter = this.setEnergyServiceBdg;
        buttonsGetter = this.getButtonsEnergyServiceForBdg;
        break;

      case "energyType":
        filterType = FilterType.Or;
        buttonSetter = this.setEnergyType;
        buttonsGetter = this.getButtonsEnergyType;
        break;

      case "perimeter":
        filterType = FilterType.Xor;
        buttonSetter = this.setPerimeter;
        break;

      case "vehicleFuels":
        filterType = FilterType.Or;
        buttonSetter = this.setVehicleFuels;
        buttonsGetter = this.getButtonsVehicleFuel;
        break;

      case "vehicleTypes":
        filterType = FilterType.Or;
        buttonSetter = this.setVehicleTypes;
        buttonsGetter = this.getButtonsVehicleType;
        break;

      case "typeOfNetworksInd":
        filterType = FilterType.Xor;
        buttonSetter = this.setNetworksInd;
        break;

      case "actionCategory":
        filterType = FilterType.Xor;
        buttonSetter = this.setActionCategory;
        break;

      case "siaAssignment":
        filterType = FilterType.Or;
        buttonSetter = this.setSiaAssignment;
        buttonsGetter = this.getButtonsSiaAssignment;
        break;

      case "energeticAgent":
        filterType = FilterType.Or;
        buttonSetter = this.setEnergeticAgent;
        buttonsGetter = this.getButtonsEnergeticAgent;
        break;
    }

    if (filterType == FilterType.Xor) {
      // Set only if desired value is true
      if (buttonValue) {
        buttonSetter(buttonId, false);
      }
    } else if (filterType == FilterType.Or) {
      const buttonToChange = this.getButtonById(buttonsGetter(), buttonId);
      // Change button only if it exists
      // And only if selected state does not correspond to the desired value
      if (buttonToChange && buttonToChange.selected != buttonValue) {
        buttonSetter(buttonToChange, false);
      }
    }

    if (update_global_data) {
      this.setAllCharts();
      this.setFilters();
    }
  }

  // GETTER
  getButtonById(buttonsList, buttonId) {
    const buttonsIds = buttonsList.map(button => {
      return button.id;
    });
    const buttonIndex = buttonsIds.indexOf(buttonId);
    return buttonsList[buttonIndex];
  }

  getButtonsValueType() {
    return this.buttonsValues;
  }
  getButtonsIndicator() {
    return this.buttonsIndicators;
  }
  getButtonsEnergyService = () => {
    return this.buttonsEnergy;
  };
  getButtonsEnergyServiceForBdg = () => {
    return this.buttonsEnergyServiceForBdg;
  };
  getButtonsEnergyType = () => {
    return this.buttonsEnergyType;
  };
  getButtonsPerimeter() {
    return this.buttonsPerimeter;
  }
  getButtonsNetworksInd() {
    return this.buttonsNetworksInd;
  }
  getButtonsVehicleFuel = () => {
    return this.buttonsVehicleFuel;
  };
  getButtonsVehicleType = () => {
    return this.buttonsVehicleType;
  };
  getButtonsActionCategory() {
    return this.buttonsActionCategory;
  }
  getButtonsSiaAssignment = () => {
    return this.buttonsSiaAssignment;
  };
  getButtonsEnergeticAgent = () => {
    return this.buttonsEnergeticAgent;
  };
  getYears() {
    this.logger.log("FilterOptionService/getYears");
    return this.GET(URLAPI + "/year/").toPromise();
  }

  getDataFilter(): DataInteraction {
    return this.dataFilters.value;
  }

  getCities() {
    this.logger.log("FilterOptionService/getCities");
    return this.getCityFromAPI().toPromise();
  }
  getCity(id): City {
    return this.cities.filter(x => x.objectId === +id)[0];
  }
  getCityFromAPI() {
    this.cities = [];
    const urlCity = URLAPI + "/city/";
    return super.GET(urlCity);
  }

  resetCitySelected() {
    this.cities.map(city => {
      city.selected = false;
    });
  }
  dataFiltersNext() {
    this.dataFilters.next(this.dataFilters.value);
  }
  setCities(res) {
    res.results.map(city => {
      let selected = false;
      if (city.objectId === defaultIdDisplayed) {
        selected = true;
      }
      this.cities.push({
        name: city.name,
        objectId: city.objectId,
        selected: selected,
        toCompare: false
      });
    });
    return this.cities;
  }
}
