import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/shared/helper';
import { Chart } from 'chart.js';


@Component({
  selector: 'app-area-measuredmeasured-barchart',
  templateUrl: './area-measuredmeasured-barchart.component.html',
  styleUrls: ['./area-measuredmeasured-barchart.component.css']
})

export class AreaMeasuredmeasuredBarchartComponent implements OnInit {
  @ViewChild('chartMeasStrategy') canvas;
  @Input() type;
  @Input() chart_id;
  @Input() options;
  @Input() indicator;
  @Input() sample_data;
  @Input() show_network_mix;
  @Input() chartData;
  @Input() value_type;
  @Input() unit;
  
  public areaChartOptions: { responsive: boolean; };
  public areaChartLabels: number[];
  public areaChartData: any;
  private chart: Chart;
  private xAxes_def;
  private yAxes_def;
  private yAxeLabel: string;
  private areaChartDatasets; 
  private show_gas_debt: boolean;
  private sampleData: true;
  
  constructor(private helper: Helper, private translateService: TranslateService) { }

  ngOnInit() {
  }

  ngOnChanges(changes){
    if (!this.helper.isNullOrUndefined(changes.chartData) && !this.helper.isNullOrUndefined(changes.chartData.currentValue)) {
      this.createAreaChart();
    }
  }

  getTranslatedLabel(labelToTranslate) {
    return this.translateService.instant('DATA.' + labelToTranslate)
  }

  createAreaChart() {
    this.resetChart();
    if (this.sample_data){
      this.areaChartData = this.getSampleData();
    }
    else{
      this.areaChartData = {labels : this.chartData[1]["measured_label"]};
      this.areaChartData['datasets'] = this.chartData[1]["measured_chart_data"];
      var i;
      for (i = 0; i < this.areaChartData['datasets'].length; i++) {
        this.areaChartData['datasets'][i]['label'] = this.getTranslatedLabel(this.areaChartData['datasets'][i]['label']);
        // this.areaChartData['datasets'][i]['label'] = "TEST";
      }
    }
    this.areaChartOptions = {
      responsive: true
    }
    // DEFINE Y LABEL WITH UNIT
    if (this.indicator == "energy"){var y_label_type = "Y_LABEL_NAME"} else if (this.indicator == "co2") {var y_label_type = "Y_LABEL_NAME_CO2"}
    this.yAxeLabel = this.translateService.instant(`DASHBOARD.CHARTS.${ this.chart_id }.${ y_label_type }`);
    var unit_from_config = this.helper.getUnitIdFromFilterValue(this.value_type, this.unit[this.indicator]);
    this.yAxeLabel += this.translateService.instant(`UNITS.${ unit_from_config["translate_unit"] }`);
    this.options['scales']['yAxes'][0]['scaleLabel']['labelString'] = this.yAxeLabel;

    this.xAxes_def = [{
      stacked: true,
      display: true,
      id: "x_def",
      ticks: {
        fontColor: 'black',
        fontSize: 14
      }
    }]
    this.yAxes_def = [{
      stacked: true,
      display: true,
      id: "mwh",
      scaleLabel: {
        display: true,
        labelString: this.yAxeLabel,
        fontColor: 'black',
        fontSize: 14
      },
      ticks: {
        fontColor: 'black',
        fontSize: 13
      }
    }]
    this.options['maintainAspectRatio'] = true;
    this.options.responsive = true;
    const ctx = this.canvas.nativeElement.getContext('2d');
    for (var j=0;j < this.areaChartData['labels'].length; j++){
      var lab = this.areaChartData['labels'][j]
      if (lab.includes(" - ")){
        this.areaChartData['labels'][j] = lab.substring(0, lab.indexOf("-") + 2) + this.getTranslatedLabel(lab.substring(lab.indexOf("-") + 2, lab.length));
      }
      else if (this.areaChartData['labels'][j].includes("obj_value")){
        this.areaChartData['labels'][j] = this.getTranslatedLabel(lab.substring(0, lab.indexOf(" "))) + lab.substring(lab.indexOf(" "), lab.length);
      }
    }
    this.chart = new Chart(ctx, {
      type: 'bar',
      data: this.areaChartData,
      options: this.options
    });
    
  }
  
  resetChart() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = void 0;
    }
  }

  getSampleData() {
    let networksChartLabels = [2015, 2016, 2017, 2018, 2019, 2020];
    let gazData = [96000, 110000, 105000, 98000, 92000, 90000];
    let dhnData = [0, 0, 4500, 5475, 6450, 12000];
    var dcnData = [0, 2000, 0, 10000, 50000, 50000];
    
    var sampleData = {
      labels: networksChartLabels,
    }
    if (!this.show_network_mix) {
      this.areaChartDatasets = [
        {
          label: 'Réseau de gaz',
          data: gazData,
          stack: "gaz",
          backgroundColor: 'rgba(50, 50, 50, 0.3)',
          borderColor: 'rgba(50, 50, 50)',
          borderWidth: 1,
          lineTension: 0,
          yAxisID: 'mwh'
        },
        {
          label: 'Réseaux CAD',
          data: dhnData,
          stack: "dhn",
          backgroundColor: 'rgba(164, 24, 211, 0.3)',
          borderColor: 'rgba(164, 24, 211)',
          borderWidth: 1,
          lineTension: 0,
          yAxisID: 'mwh'
        },
        {
          label: 'Réseaux FAD',
          data: dcnData,
          stack: "dhn",
          backgroundColor: 'rgba(0, 150, 255, 0.3)',
          borderColor: 'rgba(0, 150, 255)',
          borderWidth: 1,
          lineTension: 0,
          yAxisID: 'mwh'
        }
      ];
    }
    else if (this.show_network_mix) {
      this.areaChartDatasets = [
      {
        label: "Gaz - Gaz naturel",
        data: gazData.map(function(x) { return x * 0.95; }),
        stack: "gaz",
        backgroundColor: 'rgba(50, 50, 50, 0.3)',
        borderColor: 'rgba(50, 50, 50)',
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'mwh'
      },
      {
        label: 'Gaz - Biogaz',
        data: gazData.map(function(x) { return x * 0.05; }),
        stack: "gaz",
        backgroundColor: 'rgba(204, 153, 51, 0.3)',
        borderColor: 'rgba(204, 153, 51)',
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'mwh'
      },
      {
        label: 'CAD - Bois',
        data: dhnData.map(function(x) { return x * 0.5; }),
        stack: "dhn",
        backgroundColor: 'rgba(153, 102, 51, 0.3)',
        borderColor: 'rgba(153, 102, 51)',
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'mwh'
      },
      {
        label: 'CAD - Gaz',
        data: dhnData.map(function(x) { return x * 0.2; }),
        stack: "dhn",
        backgroundColor: 'rgba(50, 50, 50, 0.3)',
        borderColor: 'rgba(50, 50, 50)',
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'mwh'
      },
      {
        label: 'CAD - PAC',
        data: dhnData.map(function(x) { return x * 0.3; }),
        stack: "dhn",
        backgroundColor: 'rgba(51, 204, 153, 0.3)',
        borderColor: 'rgba(51, 204, 153)',
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'mwh'
      },
      {
        label: 'FAD - Eau lac',
        data: dcnData.map(function(x) { return x * 0.9; }),
        stack: "dhn",
        backgroundColor: 'rgba(0, 153, 204, 0.3)',
        borderColor: 'rgba(0, 153, 204)',
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'mwh'
      },
      {
        label: 'FAD - Elec',
        data: dcnData.map(function(x) { return x * 0.1; }),
        stack: "dhn",
        backgroundColor: 'rgba(0, 51, 153, 0.3)',
        borderColor: 'rgba(0, 51, 153)',
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'mwh'
      },
      ];
    }
    sampleData['datasets']= this.areaChartDatasets
    return sampleData;
  }
}
