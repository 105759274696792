import { defaultPanelSize } from "./../../../shared/data";
import {
  Component,
  OnInit,
  OnDestroy,
  HostListener,
  Input
} from "@angular/core";
import {
  trigger,
  state,
  style,
  transition,
  animate
} from "@angular/animations";
import { SideComponent } from "../side-panel.component";
import { DashSelectionService } from "app/shared/services/dash-selection.service";
import { DataCollectionService } from "app/shared/services/data-collection.service";

@Component({
  moduleId: module.id,
  selector: "app-left-side-panel",
  templateUrl: "left-side-panel.component.html",
  styleUrls: ["left-side-panel.component.scss"],
  animations: [
    trigger("titleColorTrigger", [
      state(
        "collapsed",
        style({ backgroundColor: "#FFFFFF", color: "#d3d3d3" })
      ),
      state(
        "expanded",
        style({ backgroundColor: "#333333", color: "#FFFFFF" })
      ),
      transition("collapsed => expanded", animate("100ms")),
      transition("expanded => collapsed", animate("100ms"))
    ]),
    trigger("titleTextTrigger", [
      state("in", style({ opacity: "1" })),
      transition("* => void", [animate("0ms", style({ opacity: "0" }))])
    ]),
    trigger("iconTrigger", [
      state("expanded", style({ transform: "rotateY(180deg)" })),
      state("collapsed", style({ transform: "rotateY(0deg)" })),
      transition("collapsed => expanded", animate("500ms ease-in")),
      transition("expanded => collapsed", animate("500ms ease-out"))
    ])
  ]
})
export class LeftSideComponent extends SideComponent
  implements OnInit, OnDestroy {
  listOfFilterValues = {};
  ascYears = false;
  @Input() isDashboard: Boolean;
  expanded = false;
  expandedState = "collapsed";
  classWidth = "defaultWidth";
  private windowWidth;
  private show_hypothesis: Boolean;
  private show_funding_support: Boolean;

  constructor(
    private dashSelectionService: DashSelectionService,
    private dataCollectionService: DataCollectionService
  ) {
    super();
  }

  get nameOfDashboard(): String {
    return this.dashSelectionService.selectedDashboard;
  }

  ngOnInit() {
    this.openPanel();
    this.windowWidth = window.innerWidth;
    this.onPanelChange();
    this._subscribeToDashName();
    this.dataCollectionService.getConfigFile().then(config => {
      this.show_hypothesis = config["global-hypothesis-in-filters"];
      this.show_funding_support = config["funding-support"];
    });
  }

  ngOnchanges() {}

  initialize() {}

  @HostListener("window:resize", ["$event"])
  sizeWindow(event) {
    this.windowWidth = event.target.innerWidth;
    this.onPanelChange();
  }

  onPanelChange() {
    if (this.expanded === false) {
      this.classWidth = "closedWidth";
    } else if (this.windowWidth <= defaultPanelSize) {
      this.classWidth = "autoWidth";
    } else {
      this.classWidth = "defaultWidth";
    }
  }

  _refreshListOfFilters(selectedDashboard) {
    this.dataCollectionService.getConfigFile().then(config => {
      const filtersDesc = config["dashboards"][selectedDashboard]["filters"];
      let newFilterValues = {};

      for (let filter of filtersDesc) {
        newFilterValues[filter["filterName"]] = filter["filterValue"];
      }
      this.listOfFilterValues = newFilterValues;
      
      if (config["sortingYear"] == "Asc") {
        this.ascYears = true;
      }
    });
  }

  _subscribeToDashName() {
    this.dashSelectionService
      .getSelectedDashboard()
      .subscribe(currentDashboard => {
        if (currentDashboard) {
          this._refreshListOfFilters(currentDashboard);
        }
      });
  }
}
