import { NavigationBarService } from '../service/navigation-bar.service';
import { Component, OnInit, Input } from '@angular/core';

import { LeftSideComponent} from '../../../features/side-panel';
import {Logger} from '../../../shared/services/logger.service';

@Component({
    selector: 'htm-nav-bar',
    templateUrl: './navigation-bar.component.html',
    styleUrls: [ './navigation-bar.component.css' ]
})

export class NavigationBarComponent implements OnInit {
    @Input() leftPanel: LeftSideComponent;
    private _navButtons: any[];
    constructor(private navService: NavigationBarService, private logger: Logger) { }
    get navButtons() {return this._navButtons}
    ngOnInit() {
      this.logger.log('NavigationBarComponent/ngOnInit')
        this._navButtons = this.navService.getButtons();
      this.logger.log('navButtons ' + this.navButtons)
    }
    toggleExpandedState(button: any) {
        this.navService.toggleBar(button);
    }
}
