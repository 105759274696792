/**
 * Created by lesly on 14.06.17.
 */

import { Routes } from '@angular/router';
import { WELCOME_PAGE } from './shared/const';
import { DashboardComponent, WebFrameComponent, WelcomeComponent} from './pages';


export const routes: Routes = [
  {path: '' , redirectTo: WELCOME_PAGE, pathMatch: 'full'},
  {path: 'dashboard' , component: DashboardComponent},
  {path: 'webframe' , component: WebFrameComponent},
  {path: 'welcome' , component: WelcomeComponent},

];
