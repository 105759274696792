import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Chart } from "chart.js";
import { TranslateService } from "@ngx-translate/core";
import { Helper } from "app/shared/helper";

@Component({
  selector: "app-electricity-production-chart",
  templateUrl: "./electricity-production-chart.component.html",
  styleUrls: ["./electricity-production-chart.component.css"]
})
export class ElectricityProductionChartComponent implements OnInit {
  @ViewChild("chartElectricityProduction") canvas;

  @Input() chartID;
  @Input() chartData;
  @Input() unit;

  private chart: Chart;
  private yAxes;
  private leftYAxisLabel;
  private rightYAxisLabel;
  private options;

  constructor(
    private helper: Helper,
    private translateService: TranslateService
  ) {}

  ngOnInit() {}

  ngOnChanges() {
    // create or update chart only when data is provided
    if (this.chartData !== undefined) {
      this.updateChartData();
      this.createChart();
    }
  }

  updateChartData() {
    // updates the recieved chart data with proper translations and units
    this.updatechartDataWithTranslation();
    this.updatechartDataForDesiredUnit();
  }

  updatechartDataWithTranslation() {
    // updates the chart datasets labels with the string provided by the translate service
    this.chartData.datasets.forEach((dataset, index: number): void => {
      let translatedLabel = this.translateService.instant(
        "DATA." + dataset.label
      );
      this.chartData.datasets[index].label = translatedLabel;
    });
  }

  updatechartDataForDesiredUnit() {
    // updates the datasets data and units to match the unit defined in the config file
    this.chartData.datasets.forEach((dataset, datasetIndex: number): void => {
      // get the conversion factor
      let currentUnit = dataset.unit[0].toLowerCase();
      let currentUnitInfo = this.helper.getUnitInfo(currentUnit);
      let desiredUnit = this.unit[dataset.physical_quantity];
      let conversionFactor = currentUnitInfo.convert_factor_from[desiredUnit];

      // convert each data value and unit
      dataset.data.forEach((dataValue, valueIndex: number): void => {
        let convertedValue = dataValue / conversionFactor;
        this.chartData.datasets[datasetIndex].data[valueIndex] = convertedValue;
        this.chartData.datasets[datasetIndex].unit[valueIndex] = desiredUnit;
        this.chartData.datasets[datasetIndex].unit[valueIndex] = desiredUnit;
      });
    });
  }

  createChart() {
    const ctx = this.canvas.nativeElement.getContext("2d");
    this.resetChart();
    this.setupYAxes();
    this.setupOptions();
    this.chart = new Chart(ctx, {
      type: "bar",
      data: this.chartData,
      options: this.options
    });
    this.alignYAxes();
  }

  setupYAxes() {
    this.getYAxesLabels();
    this.yAxes = [
      {
        id: "left",
        position: "left",
        type: "linear",
        scaleLabel: {
          display: true,
          labelString: this.leftYAxisLabel,
          fontColor: "black",
          fontSize: 14
        },
        ticks: {
          beginAtZero: true
        }
      },
      {
        id: "right",
        position: "right",
        type: "linear",
        scaleLabel: {
          display: true,
          labelString: this.rightYAxisLabel,
          fontColor: "black",
          fontSize: 14
        },
        ticks: {
          beginAtZero: true
        }
      }
    ];
  }

  getYAxesLabels() {
    // axis label is a combination of the axis label name and the axis unit
    let name; // read from the translate service
    let unit; // read from the translate service
    let unitInfo; // helps to use the translate service

    // energy axis
    name = this.translateService.instant(
      "DASHBOARD.CHARTS." + this.chartID + ".LEFT_Y_LABEL_NAME"
    );
    unitInfo = this.helper.getUnitInfo(this.unit.energy);
    unit = this.translateService.instant("UNITS." + unitInfo.translate_unit);
    this.leftYAxisLabel = name + unit;

    // power axis
    name = this.translateService.instant(
      "DASHBOARD.CHARTS." + this.chartID + ".RIGHT_Y_LABEL_NAME"
    );
    unitInfo = this.helper.getUnitInfo(this.unit.power);
    unit = this.translateService.instant("UNITS." + unitInfo.translate_unit);
    this.rightYAxisLabel = name + unit;
  }

  setupOptions() {
    this.options = {
      responsive: true,
      scales: {
        yAxes: this.yAxes
      },
      tooltips: {
        callbacks: {
          label: this.tooltipsLabelCallback
        }
      },
      legend: {
        onClick: this.legendOnClickCallback
      }
    };
  }

  /* callback has to be an anonymous function !
    elsewise "this" will not be an ElectricityProductionChartComponent
    and this.helper or this.translateService won't be available
  */
  tooltipsLabelCallback = (tooltipItem, data) => {
    let dataLabel = data.datasets[tooltipItem.datasetIndex].label + " :";
    let dataValue = tooltipItem.value;

    let unit = data.datasets[tooltipItem.datasetIndex].unit[tooltipItem.index];
    let unitTranslateKey = this.helper.getUnitInfo(unit).translate_unit;

    let convertedToSmaller = this.helper.convertTooBigValue(
      dataValue,
      unitTranslateKey
    );
    dataValue = convertedToSmaller[0];
    let dataUnit = this.translateService.instant(
      "UNITS." + convertedToSmaller[1]
    );

    return [dataLabel, dataValue + " " + dataUnit];
  };

  legendOnClickCallback = (e, legendItem) => {
    let originalAction = Chart.defaults.global.legend.onClick;
    originalAction.call(this, e, legendItem);
    this.alignYAxes();
  }

  alignYAxes() {
    // left axis is the main chart. Adapt right axis maximum and step size to fit left axis.

    // get the actual maximum and number of tick
    const maxLeftYAxis = this.chart.scales.left.max;
    const nTicks = this.chart.scales.left.ticks.length - 1; // don't count the last tick
    const maxRightYAxis = this.chart.scales.right.max;

    let newRightMax = maxRightYAxis;

    // correct right maximum if not divisible by number of ticks
    if (newRightMax % nTicks !== 0) {
      newRightMax = nTicks * Math.floor(maxRightYAxis / nTicks) + nTicks;
    }
    let newRightStep = newRightMax / nTicks;

    // update chart with the new maximum
    this.chart.options.scales.yAxes[1].ticks.maxTicksLimit = newRightMax; // ugly hard coded value, should search through axes for the one with the correct id
    this.chart.options.scales.yAxes[1].ticks.stepSize = newRightStep;
    this.chart.update();
  }

  resetChart() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = void 0;
    }
  }
}
