import {
  Component,
  OnInit,
  OnDestroy
} from '@angular/core';


@Component({
  moduleId: module.id,
  template:``
})
export class SideComponent implements OnInit, OnDestroy {
  protected expanded = false;
  protected title = 'SideComponent title';
  protected expandedState = 'collapsed';
  constructor() {
  }



  setTitle(title: string) {
    this.title = title
  }
  getTitle(): string {
    return this.title;
  }
  ngOnInit() {
  }
  ngOnDestroy() {
  }
  closePanel() {
    this.expanded = false;
    this.expandedState = 'collapsed';
  }
  openPanel() {
    this.expanded = true;
    this.expandedState = 'expanded';
  }
  toggleExpandedState() {
    this.expandedState = this.expanded ? 'collapsed' : 'expanded';
    this.expanded = !this.expanded;
  }

  display(val: boolean) {
    if (val) {
      this.expandedState = 'expanded';
      this.expanded = true;
    } else {
      this.expandedState = 'collapsed';
      this.expanded = false;
    }
  }
}
