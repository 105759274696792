import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs/Rx';
import { DataCollectionService } from './data-collection.service';
import { DEFAULT_DASHBOARD } from '../const';

@Injectable({
  providedIn: 'root'
})

export class DashSelectionService{
  private _availableDashboards: BehaviorSubject<string[]>;
  private _selectedDashboard: BehaviorSubject<string>;

  constructor(dataCollectionService: DataCollectionService, private activatedRoute: ActivatedRoute){
    this._availableDashboards = new BehaviorSubject<string[]>([DEFAULT_DASHBOARD]);
    this._selectedDashboard = new BehaviorSubject<string>(DEFAULT_DASHBOARD);
    dataCollectionService.getConfigFile().then(config => {
      let availableDashboards = Object.keys(config['dashboards']);
      const paramDashboards = this.activatedRoute.snapshot.queryParamMap.get('dashboards');
      if (!paramDashboards) {
        return;
      }
      const displayableDashboards = new Set(paramDashboards.split(','));
      if (displayableDashboards.has('demo')){ 
        availableDashboards = ['public', 'demo'];
      }
      else if (displayableDashboards.has('all')){
        availableDashboards = availableDashboards.filter(a => !a.includes('demo'));
      }
      else if (!displayableDashboards.has('all')){
        availableDashboards = availableDashboards.filter(a => displayableDashboards.has(a));
      }
      this._availableDashboards.next(availableDashboards);
      // this._selectedDashboard = new BehaviorSubject<string>(availableDashboards[0]);
    });
  }

  public getAvailableDashboard() {
    return this._availableDashboards.asObservable();
  }

  public getSelectedDashboard() {
    return this._selectedDashboard;
  }

  public set selectedDashboard(dashboard: string) {
    this._selectedDashboard.next(dashboard);
  }

  setNewDashboard(dashboard_label){
    this._selectedDashboard.next(dashboard_label.toLowerCase());
  }
}