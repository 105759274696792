import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class SidePanelService {

    // status for left panel
    public leftPanelStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    openLeftPanel() {
      this.leftPanelStatus.next(true);
    }
    closeLeftPanel() {
      this.leftPanelStatus.next(false);
    }
    toggleLeftPanel() {
      if (this.leftPanelStatus.getValue()) {
        this.closeLeftPanel()
      } else {
        this.openLeftPanel()
      }
    }
}

