import {
  NAME_REGION,
  MULTICITY_PARAM,
  DEFAULT_DASHBOARD,
  COMPARE_PIE_OPTION,
  COMPARE_PIE_BOOL,
  OVERLAP_OPT,
  BARCHART_FOR_MEAS
} from "./../../shared/const";
import {
  defaultIdDisplayed,
  idChartMix,
  idChartRenewable,
  idChartLocal,
  idChartEvolution,
  idChartActual
} from "./../../shared/data";
import { Component, OnInit, Input } from "@angular/core";

import { OnChanges } from "@angular/core/src/metadata/lifecycle_hooks";
import { Helper } from "./../../shared/helper";
import { ChartsService } from "./charts.service";
import {
  chartOptionsBarChart,
  chartOptionsMobilityChart,
  chartType,
  chartOptionsPiechart,
  chartOptionsAreachart,
  chartOptionsLinechart
} from "./chart.data";
import { TranslatePipe, TranslateService } from "@ngx-translate/core";
import { Logger } from "app/shared";
import { trigger, transition, style, animate } from "@angular/animations";
import { LoaderService } from "app/shared";
import { DataCollectionService } from "app/shared/services/data-collection.service";
import { FilterOptionService } from "app/features/filter-options/filter-options.service";
import { DataInteraction } from "app/shared/data-interaction";

@Component({
  selector: "app-charts",
  templateUrl: "./charts.component.html",
  animations: [
    trigger("enterAnimation", [
      transition(":enter", [
        style({ height: "0px", transform: "translateY(-100%)", opacity: 0 }),
        animate(
          "500ms",
          style({ height: "250px", transform: "translateY(0)", opacity: 1 })
        )
      ]),
      transition(":leave", [
        style({ height: "250px", transform: "translateY(0)", opacity: 1 }),
        animate(
          "500ms",
          style({ height: "0px", transform: "translateY(-100%)", opacity: 0 })
        )
      ])
    ])
  ],
  styleUrls: ["./charts.component.scss"]
})
export class ChartsComponent implements OnChanges {
  @Input() dataFilters: DataInteraction;
  @Input() dashToDisplay;
  @Input() configData;

  private title = "DASHBOARD";
  private pieChartWidth: String;
  private multiCity = MULTICITY_PARAM;
  bar = "bar";
  private _chartType = chartType;
  private chartParam; // parameters for the charts of the current dashboard (read from config file)
  private chartsToCompute;
  private chartData = {}; // contains data for rendering the all charts (all the charts received by the get request)
  private datasetView = [];
  private selectedCity;
  private comparePieChartParam = COMPARE_PIE_BOOL;
  private comparePieOption = COMPARE_PIE_OPTION;
  public makeTest: boolean;
  public selectedItemsList: any;
  private comparingYearForPie: number;
  private comparingRegionForPie: string;
  private overlapOpt = OVERLAP_OPT;
  private overlapOpt_bool: boolean;
  private barChartForMeas = BARCHART_FOR_MEAS;
  private barChartForMeas_bool: boolean;

  constructor(
    private dataCollectionService: DataCollectionService,
    private helper: Helper,
    private chartsService: ChartsService,
    private translateService: TranslateService,
    private logger: Logger,
    private filterOptionsService: FilterOptionService
  ) {}

  get chartType() {
    return this._chartType;
  }

  ngOnChanges(changes) {
    if (changes.configData || changes.dashToDisplay) {
      this.updateConfigdata();
    }

    if (changes.dashToDisplay) {
      if (changes.dashToDisplay.previousValue) {
        this.updateFilterValues();
      }
    } else if (!this.helper.isNullOrUndefined(changes.dataFilters)) {
      // Update the charts when the filters change
      // Update the charts only if the filters are defined
      if (!this.helper.isNullOrUndefined(changes.dataFilters.currentValue)) {
        // proceed differently if the energy service filter is displayed
        if (this.isEnergyServiceFilterDisplayed()) {
          // read the filters values
          const currentFiltersValues = changes.dataFilters.currentValue;
          // proceed only if the filter for energy service is defined
          if (
            !this.helper.isNullOrUndefined(currentFiltersValues.energy_service)
          ) {
            // test if AT LEAST 1 energy service is selected
            if (currentFiltersValues.energy_service.length >= 1) {
              // Yes, update the charts
              this.update();
            } else {
              // No, don't display the charts
              this.chartParam.map(ch => {
                this.deleteChartData(ch.id);
              });
            }
          }
        }
        else{
          this.update();
        }
      }
    }
  }

  isEnergyServiceFilterDisplayed(): boolean {
    let isDisplayed = false;
    if (this.configData && this.dashToDisplay) {
      this.configData["dashboards"][this.dashToDisplay][
        "filters"
      ].map(filterData => {
        isDisplayed =
          isDisplayed || filterData["filterName"] == "energyService";
      });
    }
    return isDisplayed;
  }

  updateConfigdata() {
    if (this.configData && this.dashToDisplay) {
      this.barChartForMeas = this.configData["dashboards"][
        this.dashToDisplay.toLowerCase()
      ]["barChartForMeasuredData"];

      this.chartParam = this.configData["dashboards"][
        this.dashToDisplay.toLowerCase()
      ]["charts"];

      if (this.comparePieChartParam == "True") {
        this.pieChartWidth = "46%";
      } else {
        this.pieChartWidth = "98%";
        this.comparePieOption = null;
      }
    }
  }

  updateFilterValues() {
    // update the filter values only if all the necessary data is available (configuration and selected dashboard)
    if (this.configData && this.dashToDisplay) {
      let filterValues = {};
      const currentFilterConfig = this.configData["dashboards"][
        this.dashToDisplay.toLowerCase()
      ]["filters"];

      for (let filterConfig of currentFilterConfig) {
        filterValues[filterConfig["filterName"]] = filterConfig["filterValue"];
      }

      // todo: read default values for "inline filters"

      this.filterOptionsService.setDefaultFilterValues(filterValues);
    }
  }

  public changeSelection(chart_param) {
    this.selectedItemsList = chart_param.objectivesInfo.filter(
      (value, index) => {
        return value.isChecked;
      }
    );
  }

  getChartOption(type) {
    if (type == this._chartType.bar) {
      chartOptionsBarChart["maintainAspectRatio"] = false;
      chartOptionsBarChart.legend.display = false;
      return chartOptionsBarChart;
    } else if (type == this._chartType.piechart) {
      return chartOptionsPiechart;
    } else if (type == this._chartType.area) {
      return chartOptionsAreachart;
    } else if (type == this._chartType.line) {
      return chartOptionsLinechart;
    }
  }

  getMobilityChartOption() {
    return chartOptionsMobilityChart;
  }

  getTranslatedTitle(data) {
    return this.translateService
      .instant("FILTERS.TYPE." + data.toUpperCase())
      .replace("<sub>", "")
      .replace("</sub>", "");
  }

  getChartIdToUpdate(filteringDict) {
    var filteredArray = [];
    for (let i = 0; i < filteringDict.length; i++) {
      filteredArray.push(filteringDict[i]["id"]);
    }
    return filteredArray;
  }
  createPayload() {
    if (this.chartParam) {
      this.chartsToCompute = this.getChartIdToUpdate(this.chartParam);
    } else {
      this.chartsToCompute = this.dataFilters.charts;
    }
    this.selectedCity = this.dataFilters.cities.filter(
      x => x.selected === true
    )[0];
    const comparedCity = [];
    let cityId;
    this.dataFilters.cities.filter(data => {
      if (data.toCompare) {
        comparedCity.push(data.objectId);
      }
    });
    if (this.comparePieChartParam) {
      if (this.comparePieOption == "region") {
        this.comparingRegionForPie = "region"; //this.comparePieOption.toString();
        this.comparingYearForPie = null;
      } else if (Number.isInteger(parseInt(this.comparePieOption))) {
        this.comparingYearForPie = parseInt(this.comparePieOption);
        this.comparingRegionForPie = null;
      } else {
        this.comparingYearForPie = null;
        this.comparingRegionForPie = null;
      }
    } else {
      this.comparingYearForPie = null;
      this.comparingRegionForPie = null;
    }
    if (this.helper.isNullOrUndefined(this.selectedCity)) {
      cityId = defaultIdDisplayed;
      this.datasetView = this.dataFilters.energy_service;
    } else {
      cityId = this.selectedCity.objectId;
      this.datasetView = this.dataFilters.energy_service;
    }
    if (this.overlapOpt === "True") {
      this.overlapOpt_bool = true;
    } else {
      this.overlapOpt_bool = false;
    }
/*     if (this.barChartForMeas === "True") {
      this.barChartForMeas_bool = true;
    } else {
      this.barChartForMeas_bool = false;
    } */
    return {
      city_compare: comparedCity,
      city_id: cityId,
      indicator: this.dataFilters.indicator,
      services: this.datasetView,
      services_bdg: this.dataFilters.energy_service_bdg,
      energy_type: this.dataFilters.energy_type,
      display_overlapp_meas_data: this.overlapOpt_bool,
      display_meas_data_on_bar_chart: this.barChartForMeas,
      value_type: this.dataFilters.value_type,
      perimeter: this.dataFilters.perimeter,
      network_ind: this.dataFilters.networksInd,
      vehicle_fuel: this.dataFilters.vehicleFuel,
      vehicle_type: this.dataFilters.vehicleType,
      action_category: this.dataFilters.actionCategory,
      sia_assignment: this.dataFilters.siaAssignment,
      energetic_agent: this.dataFilters.energeticAgent,
      charts: this.chartsToCompute,
      multi_city: this.multiCity,
      pieChartsObjComp: this.comparingYearForPie,
      pieChartsRegionComp: this.comparingRegionForPie,
      year: this.dataFilters.year
    };
  }

  update(): void {
    this.logger.log("chartsComponent/update");
    if (!this.helper.isNullOrUndefined(this.dataFilters)) {
      this.updateAllCharts();
    }
  }

  updateAllCharts() {
    // request the data for the charts (using a newly created payload)
    this.chartsService.getCharts(this.createPayload()).then(data => {
      // once the data received
      // update charts one by one using the requested data
      const chartsToUpdate = Object.keys(data);
      chartsToUpdate.forEach(chartId => {
        this.updateChart(chartId, data[chartId]);
      });
    });
  }

  updateChart(idOfChartData, data) {
    this.deleteChartData(idOfChartData);
    if (Object.keys(data).length !== 0) {
      this.chartData[idOfChartData] = data;
    }
  }

  deleteChartData(idOfChartData) {
    delete this.chartData[idOfChartData];
  }

  getOrderedParam() {
    if (!this.helper.isNullOrUndefined(this.chartParam)) {
      return this.helper.getOrderedDictByOrderName(this.chartParam);
    }
    return [];
  }
}
