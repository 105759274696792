import { URLAPI } from '../../shared/const';
import { apiChartEndpoint, apiStrategyChartEndpoint } from './chart.data';
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { LoaderService, ToasterService, Logger, APIService } from 'app/shared';


@Injectable()

export class ChartsService extends APIService {

  constructor(protected http: Http, protected logger: Logger, protected loaderService: LoaderService,
      protected toasterService: ToasterService) {
      super(http, logger, loaderService, toasterService);
  }
  getCharts(payload){
    this.logger.log(JSON.stringify(payload));
    return super.POST(payload, URLAPI + apiChartEndpoint);
  }
  /* getStrategyCharts(payload){
    this.logger.log(JSON.stringify(payload));
    return super.POST(payload, URLAPI + apiStrategyChartEndpoint);
  } */
}
