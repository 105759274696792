import { Component, AfterContentInit, OnInit, ViewChild, AfterViewInit } from '@angular/core';

import { LoaderService } from './shared/services/loader.service';
import { LeftSideComponent } from './features/side-panel/left-side-panel/left-side-panel.component';
import { SidePanelService } from './features/side-panel/side-panel.service';
import { Logger } from './shared/services/logger.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataCollectionService } from './shared/services/data-collection.service';
import { Helper } from './shared/helper';
import { Title } from '@angular/platform-browser';
import { GOOGLE_ANA_ID } from './shared/const';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: [require<any>('./app.component.scss')]
})

export class AppComponent implements OnInit, AfterContentInit {
  title = 'Dashboard';
  // management of initial status of general loading
  showLoader: boolean;
  showMainLoader: boolean;
  openLeftSidebar = false;

  private _isDashboard;
  private _hide = false;
  private _hideWelcomeButton = false;
  private _location = '';
  private _translated_language;
  private _languages = [];
  private _google_analytics_id = '';
  private _browserLang;
  private _languageTitle;
  private _dateLastUpdate;
  private _googleAnalyticsSrc;
  private _googleAnalyticsID;
  public googleAnalyticsSrc = GOOGLE_ANA_ID;
  webframeStatus;


  @ViewChild(LeftSideComponent) leftPanelComponent: LeftSideComponent;
  constructor(
    private loaderService: LoaderService, private panelService: SidePanelService, private logger: Logger,
    private _router: Router, private translate: TranslateService, private dataCollectionService: DataCollectionService,
    private helper: Helper, private titleService: Title
  ) {

  }
  get router() { return this._router }
  get hide() { return this._hide }
  get hideWelcomeButton() { return this._hideWelcomeButton };
  get isDashboard() { return this._isDashboard }
  get location() { return this._location }
  get translated_language() { return this._translated_language }
  get languages() { return this._languages }
  get googleSrc() { return `https://www.googletagmanager.com/gtag/js?id=${this._google_analytics_id}`}
  get googleId() { return this._google_analytics_id }
  get browserLang() { return this._browserLang }
  get languageTitle() { return this._languageTitle }
  get dateLastUpdate() { return this._dateLastUpdate }

  ngAfterContentInit(): void {
    this.logger.log('AppComponent/ngAfterContentInit');
    this.notifyLoaderService();
    this.leftPanelComponent.setTitle('Filter options');
  }
  notifyLoaderService() {
    this.logger.log('AppComponent/notifyLoaderService');
    this.loaderService.status.subscribe((val: boolean) => {
      this.logger.log('loaderService.status/' + val)
      this.showLoader = val;
    });

    this.router.events.subscribe((val) => {
      if (this.router.url.startsWith('/dashboard')) {
        this._hide = false;
        this._isDashboard = true;
        /* if (this.router.url.startsWith('/dashboard?dashboards')){
          this._hideWelcomeButton = true;
        } */
      } else {
        this._hide = true;
        this._isDashboard = false;
      }
    });
    this.loaderService.main_loader.subscribe((val: boolean) => {
      this.logger.log('loaderService.main_loader/' + val)
      this.showMainLoader = val;
    });
  }
  ngOnInit() {
    this.logger.log('AppComponent/ngOnInit');
    this.dataCollectionService.getConfigFile().then(data => {
      this._google_analytics_id = data['googleAnalyticsID'];
      this._languages = data['translate'];
      this.title = data['title'];
      this.titleService.setTitle(this.title);
      this._hideWelcomeButton = !data['display-welcome-page'];
      this.initTranslate()
    })
    this.leftPanelComponent.initialize();
    this.dataCollectionService.webframeExists().then(data => {
      this.webframeStatus = data;
    })
    this.getDateLastUpdate()

    
    this.initSubriptions();
    setTimeout(() => {
      this.loaderService.displayMainLoader(false)
    }, 1000);

  }
  initSubriptions() {

    this.logger.log('AppComponent.initSubriptions')
    this.panelService.leftPanelStatus.subscribe((val: boolean) => {
      this.logger.log('leftPanelStatus/' + val)
      if (val === true) {
        this.leftPanelComponent.openPanel();
        this.openLeftSidebar = true;
      } else {
        this.leftPanelComponent.closePanel();
        this.openLeftSidebar = false;
      }
    });
    
  }
  initTranslate() {

    this._translated_language = this.languages.map((langue) => {
      return langue.id
    });
    this.translate.addLangs(this.translated_language);
    this.translate.setDefaultLang(this._translated_language);
    this.translate.getLangs()
    this._browserLang = this.translate.getBrowserLang();
    if (this.translated_language.find(x => x === this.browserLang)) {
      this.setLanguage(this.browserLang);
    } else {
      this.setDefaultLanguageTitle(this.getIdDefaultLanguage())
    }
  }
  goToDashboard() {
    this.router.navigate(['/dashboard']);
  }
  getIdDefaultLanguage() {
    return this._languages.filter(x => x.default === true)[0].id
  }
  setLanguage(id) {
    this._languageTitle = id;
    this.translate.use(id);
  }

  setDefaultLanguageTitle(id) {
    this.languages.find((x) => {
      if (x.id == id) {
        this._languageTitle = x.webviewName;
        return true
      }
    })
  }

  goToWeb() {
    this.router.navigate(['/webframe']);
  }

  goToInfo() {

    this.router.navigate(['welcome']);
  }
  getDateLastUpdate() {
    this.dataCollectionService.getLastUpdateDate().subscribe((date) => {
      this._dateLastUpdate = date.date;
    })
  }
}
