import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/shared/helper';

@Component({
  selector: 'app-electricity-cons-chart',
  templateUrl: './electricity-cons-chart.component.html',
  styleUrls: ['./electricity-cons-chart.component.css']
})
export class ElectricityConsChartComponent implements OnInit {
  @ViewChild('chartElecCons') canvas;
  @Input() type;
  @Input() chart_id;
  @Input() options;
  @Input() unit;
  @Input() indicator;
  @Input() sample_data;
  @Input() show_network_mix;
  @Input() chartData;
  @Input() value_type;
  @Input() yAxeLabel: string;
  
  public areaChartOptions: { responsive: boolean; };
  public areaChartLabels: number[];
  public elecChartData: any;
  private chart: Chart;
  private yAxes_def;
  private xAxes_def;
  private areaChartDatasets; 
  private show_gas_debt: boolean;
  
  constructor(private helper: Helper, private translateService: TranslateService) { }

  ngOnInit() {
  }

  ngOnChanges(){
    this.defineIfShowgasDebt();
    this.createAreaChart();
  }

  getTranslatedLabel(labelToTranslate) {
    return this.translateService.instant('DATA.' + labelToTranslate)
  }

  createAreaChart() {
    this.resetChart();
    if (this.sample_data){
      this.elecChartData = this.getSampleData();
    }
    else {
      this.elecChartData = this.chartData;
      var i;
      if(typeof this.elecChartData !== 'undefined'){
        for (i = 0; i < this.elecChartData['datasets'].length; i++) {
          this.elecChartData['datasets'][i]['label'] = this.getTranslatedLabel(this.elecChartData['datasets'][i]['label']);
        }
      }      
    }
    this.areaChartOptions = {
      responsive: true
    }
    // DEFINE Y AXIS  LABEL WITH UNIT
    if (this.indicator == "energy"){var y_label_type = "Y_LABEL_NAME"} else if (this.indicator == "co2") {var y_label_type = "Y_LABEL_NAME_CO2"}
    this.yAxeLabel = this.translateService.instant(`DASHBOARD.CHARTS.${ this.chart_id }.${ y_label_type }`);
    var unit_from_config = this.helper.getUnitIdFromFilterValue(this.value_type, this.unit[this.indicator]);
    this.yAxeLabel += this.translateService.instant(`UNITS.${ unit_from_config["translate_unit"] }`);
    
    const ctx = this.canvas.nativeElement.getContext('2d');
    this.xAxes_def = [{
      stacked: true,
      display: true,
      id: "x_def",
      ticks: {
        fontColor: 'black',
        fontSize: 12
      }
    }]
    this.yAxes_def = [{
      stacked: true,
      display: true,
      id: "mwh",
      scaleLabel: {
        display: true,
        labelString: this.yAxeLabel,
        fontColor: 'black',
        fontSize: 14
        },
      ticks: {
        callback: value => {
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
        },
        fontColor: 'black',
        fontSize: 12
      }
    }]
    if (this.show_gas_debt){
      this.yAxes_def.push({
        stacked: true,
        display: true,
        id: "debt-CHF",
        position: 'right',
        gridLines: {
          display: false
        },
        scaleLabel: {
          display: true,
          labelString: 'Dette du gaz en CHF',
          fontColor: 'red',
          fontSize: 14
          },
        ticks: {
          fontColor: 'red',
          fontSize: 12
        }
      })
    }
    this.chart = new Chart(ctx, {
      type: this.type,
      data: this.elecChartData,
      options: {
        responsive: true,
        scales: {
          yAxes: this.yAxes_def
        }
      }
    });
    
  }
  
  resetChart() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = void 0;
    }
  }

  private defineIfShowgasDebt(){
  }

  getSampleData() {
    let networksChartLabels = [2015, 2016, 2017, 2018, 2019, 2020];
    let opaleData = [96000, 110000, 105000, 98000, 92000, 90000];
    let topazeData = [0, 0, 4500, 5475, 6450, 12000];
    var ambreData = [0, 2000, 0, 10000, 50000, 50000];
    
    var sampleData = {
      labels: networksChartLabels,
    }
    if (!this.show_network_mix) {
      this.areaChartDatasets = [
        {
          label: 'Opale',
          data: opaleData,
          stack: "stack-elec",
          backgroundColor: 'rgba(50, 50, 50, 0.3)',
          borderColor: 'rgba(50, 50, 50)',
          borderWidth: 1,
          lineTension: 0,
          yAxisID: 'mwh'
        },
        {
          label: 'Topaze',
          data: topazeData,
          stack: "stack-elec",
          backgroundColor: 'rgba(164, 24, 211, 0.3)',
          borderColor: 'rgba(164, 24, 211)',
          borderWidth: 1,
          lineTension: 0,
          yAxisID: 'mwh'
        },
        {
          label: 'Ambre',
          data: ambreData,
          stack: "stack-elec",
          backgroundColor: 'rgba(0, 150, 255, 0.3)',
          borderColor: 'rgba(0, 150, 255)',
          borderWidth: 1,
          lineTension: 0,
          yAxisID: 'mwh'
        }
      ];
    }
    else if (this.show_network_mix) {
      this.areaChartDatasets = [
      {
        label: "Gaz - Gaz naturel",
        data: opaleData.map(function(x) { return x * 0.95; }),
        stack: "gaz",
        backgroundColor: 'rgba(50, 50, 50, 0.3)',
        borderColor: 'rgba(50, 50, 50)',
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'mwh'
      },
      {
        label: 'Gaz - Biogaz',
        data: opaleData.map(function(x) { return x * 0.05; }),
        stack: "gaz",
        backgroundColor: 'rgba(204, 153, 51, 0.3)',
        borderColor: 'rgba(204, 153, 51)',
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'mwh'
      },
      {
        label: 'CAD - Bois',
        data: opaleData.map(function(x) { return x * 0.5; }),
        stack: "dhn",
        backgroundColor: 'rgba(153, 102, 51, 0.3)',
        borderColor: 'rgba(153, 102, 51)',
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'mwh'
      },
      {
        label: 'CAD - Gaz',
        data: opaleData.map(function(x) { return x * 0.2; }),
        stack: "dhn",
        backgroundColor: 'rgba(50, 50, 50, 0.3)',
        borderColor: 'rgba(50, 50, 50)',
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'mwh'
      },
      {
        label: 'CAD - PAC',
        data: opaleData.map(function(x) { return x * 0.3; }),
        stack: "dhn",
        backgroundColor: 'rgba(51, 204, 153, 0.3)',
        borderColor: 'rgba(51, 204, 153)',
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'mwh'
      },
      {
        label: 'FAD - Eau lac',
        data: opaleData.map(function(x) { return x * 0.9; }),
        stack: "dhn",
        backgroundColor: 'rgba(0, 153, 204, 0.3)',
        borderColor: 'rgba(0, 153, 204)',
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'mwh'
      },
      {
        label: 'FAD - Elec',
        data: opaleData.map(function(x) { return x * 0.1; }),
        stack: "dhn",
        backgroundColor: 'rgba(0, 51, 153, 0.3)',
        borderColor: 'rgba(0, 51, 153)',
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'mwh'
      },
      ];
    }
    if (this.show_gas_debt){
      this.areaChartDatasets.push({
        label: 'Dette du gaz',
        pointRadius: 0,
        data: [1000000, 900000, 850000, 830000, 820000, 820000],
        type: 'line',
        fill: false,
        fillStyle: 'rgba(50, 50, 50, 0)',
        borderColor: [
          'red'
        ],
        borderWidth: 2,
        borderDash: [5, 8],
        lineTension: 0,
        yAxisID: 'debt-CHF'
      })
    }
    sampleData['datasets']= this.areaChartDatasets
    return sampleData;
  }
}

