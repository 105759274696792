import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { Chart } from "chart.js";
import { TranslateService } from "@ngx-translate/core";
import { Helper } from "app/shared/helper";

@Component({
  selector: 'app-public-consumption',
  templateUrl: './public-consumption.component.html',
  styleUrls: ['./public-consumption.component.css']
})
export class PublicConsumptionComponent implements OnInit {
  @ViewChild("publicConsumption") canvas;
  @Input() chartData;
  @Input() chartID;
  @Input() unit;

  private chart: Chart;
  private options;
  private yAxes;
  private leftYAxisLabel;
  private rightYAxisLabel;

  constructor(
    private helper: Helper,
    private translateService: TranslateService
  ) {}

  ngOnInit() {}

  ngOnChanges() {
    // create or update chart only when data is provided
    if (this.chartData !== undefined) {
      this.updateChartData();
      this.createChart();
    }
  }

  updateChartData() {
    // updates the recieved chart data with proper translations and units
    this.updatechartDataWithTranslation();
    this.updatechartDataForDesiredUnit();
  }

  updatechartDataWithTranslation() {
    // updates the chart datasets labels with the string provided by the translate service
    this.chartData.datasets.forEach((dataset, index: number): void => {
      let translatedLabel = this.translateService.instant(
        "DATA." + dataset.label
      );
      this.chartData.datasets[index].label = translatedLabel;
    });
  }

  updatechartDataForDesiredUnit() {
    // updates the datasets data and units to match the unit defined in the config file
    this.chartData.datasets.forEach((dataset, datasetIndex: number): void => {
      // get the conversion factor
      let currentUnit = dataset.unit[0].toLowerCase();
      let currentUnitInfo = this.helper.getUnitInfo(currentUnit);
      let desiredUnit = this.unit[dataset.physical_quantity];
      let conversionFactor = currentUnitInfo.convert_factor_from[desiredUnit];

      // convert each data value and unit
      dataset.data.forEach((dataValue, valueIndex: number): void => {
        let convertedValue = dataValue / conversionFactor;
        this.chartData.datasets[datasetIndex].data[valueIndex] = convertedValue;
        this.chartData.datasets[datasetIndex].unit[valueIndex] = desiredUnit;
        this.chartData.datasets[datasetIndex].unit[valueIndex] = desiredUnit;
      });
    });
  }

  createChart() {
    const ctx = this.canvas.nativeElement.getContext("2d");
    this.resetChart();
    this.setupYAxes();
    this.setupOptions();
    this.chart = new Chart(ctx, {
      type: "bar",
      data: this.chartData,
      options: this.options
    });
  }

  setupYAxes() {
    this.getYAxesLabels();
    this.yAxes = [
      {
        id: "left",
        position: "left",
        type: "linear",
        scaleLabel: {
          display: true,
          labelString: this.leftYAxisLabel,
          fontColor: "black",
          fontSize: 14
        },
        ticks: {
          beginAtZero: true
        },
        stacked: true
      },
      {
        id: "right",
        position: "right",
        type: "linear",
        scaleLabel: {
          display: true,
          labelString: this.rightYAxisLabel,
          fontColor: "black",
          fontSize: 14
        },
        ticks: {
          beginAtZero: true
        },
        gridLines: {
          display: false
        }
      }
    ];
  }

  getYAxesLabels() {
    // axis label is a combination of the axis label name and the axis unit
    let name; // read from the translate service
    let unit; // read from the translate service
    let unitInfo; // helps to use the translate service

    // energy axis
    name = this.translateService.instant(
      "DASHBOARD.CHARTS." + this.chartID + ".LEFT_Y_LABEL_NAME"
    );
    unitInfo = this.helper.getUnitInfo(this.unit.energy);
    unit = this.translateService.instant("UNITS." + unitInfo.translate_unit);
    this.leftYAxisLabel = name + unit;

    // power axis
    name = this.translateService.instant(
      "DASHBOARD.CHARTS." + this.chartID + ".RIGHT_Y_LABEL_NAME"
    );
    unitInfo = this.helper.getUnitInfo(this.unit.co2);
    unit = this.translateService.instant("UNITS." + unitInfo.translate_unit);
    this.rightYAxisLabel = name + unit;
  }

  setupOptions() {
    this.options = {
      responsive: true,
      scales: {
        yAxes: this.yAxes
      },
      tooltips: {
        callbacks: {
          label: this.tooltipsLabelCallback
        }
      },
      legend: {
        onClick: e => e.stopPropagation()
      }
    };
  }

  tooltipsLabelCallback = (tooltipItem, data) => {
    let dataLabel = data.datasets[tooltipItem.datasetIndex].label + " :";
    let dataValue = tooltipItem.value;

    let unit = data.datasets[tooltipItem.datasetIndex].unit[tooltipItem.index];
    let unitTranslateKey = this.helper.getUnitInfo(unit).translate_unit;

    let convertedToSmaller = this.helper.convertTooBigValue(
      dataValue,
      unitTranslateKey
    );
    dataValue = convertedToSmaller[0];
    let dataUnit = this.translateService.instant(
      "UNITS." + convertedToSmaller[1]
    );

    return [dataLabel, dataValue + " " + dataUnit];
  };

  resetChart() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = void 0;
    }
  }
}
