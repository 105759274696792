import { Helper } from './../../shared/helper';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Kpi } from 'app/features/kpi/kpi';
import { DataCollectionService } from 'app/shared/services/data-collection.service';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { LoaderService } from 'app/shared';
import { ThounsandSeparatorPipe } from 'app/shared/pipes/thounsand-separator.pipe';
import { RoundNumberPipe } from 'app/shared/pipes/round-number.pipe';
import { DashSelectionService } from 'app/shared/services/dash-selection.service';
import { Observable } from 'rxjs';
import { DataInteraction } from 'app/shared/data-interaction';
import { isNullOrUndefined } from 'util';
import { MULTICITY_PARAM } from 'app/shared';
import * as uikit from 'uikit';

@Component({
  selector: 'app-kpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.scss'],
})
export class KpiComponent {
  @Input() dataFilters: DataInteraction;
  selectedDashboard: string;
  selectedDashboard$: Observable<string>;
  private title = 'KPIs';
  private titleData;
  private _kpis = [];
  private selectedCity;
  private config_kpis;
  private kpis_from_config_file;
  private unit_kpi;
  private year;
  private multiCityParam: String;

  constructor(private dataCollectionService: DataCollectionService,
    private helper: Helper, private loaderService:LoaderService,
    private dashSelectionService: DashSelectionService, private translateService: TranslateService) { 
      this.selectedDashboard$ = dashSelectionService.getSelectedDashboard();
      this.selectedDashboard$.subscribe(
        (selectedDashboard) => {
          this.selectedDashboard = selectedDashboard;
          this.refreshDashboard();
      })
      this.multiCityParam = MULTICITY_PARAM;
    }

  get kpis() { return this._kpis}

  ngOnChanges(changes){
    if (this.helper.isNullOrUndefined(changes.dataFilters)){
      return;
    }
    if (this.helper.isNullOrUndefined(changes.dataFilters.currentValue)){ 
      return;
    }
    const currVal = changes.dataFilters.currentValue;
    if (this.helper.isNullOrUndefined(currVal.energy_service)){ 
      return;
    }
    this.refreshDashboard()
  }

  refreshDashboard() {
    this.dataCollectionService.getConfigFile().then(data => {
      this.kpis_from_config_file = data['dashboards'][this.selectedDashboard]['kpis'];
      this.update();
    })
  }

  update() {
    if (this.dataFilters) {
      const city = this.dataFilters.cities.filter(x => x.selected === true);
      if (city.length !== 0 && !this.helper.isNullOrUndefined(this.dataFilters.year)) {
        this.filterKpisToDisplay();
        if (city[0].name !== this.selectedCity || this.year != this.dataFilters.year || this.config_kpis) {
          this.year = this.dataFilters.year;
          this.selectedCity = city[0].name;
          var arrayKpisStringList = ""; var i; 
          for (i=0;i < (this.config_kpis.length - 1);i++){
            arrayKpisStringList += `${this.config_kpis[i]["id"]},`;
          }
          arrayKpisStringList += this.config_kpis[i]["id"];
          //const promise = this.dataCollectionService.getKPI(city[0].objectId, this.year.objectId, arrayKpisStringList).toPromise();
          this.dataCollectionService.getKPI(city[0].objectId, this.year.objectId, arrayKpisStringList).subscribe((data) => {
          this._kpis = data.results; var i;
          var kpis_keys = [];
          var config_kpis_keys = [];
          for (var item in this._kpis){
              kpis_keys.push(this._kpis[item]["name"]);
          }
          for (var item in this.config_kpis){
              config_kpis_keys.push(this.config_kpis[item]["id"]);
          }
          if(kpis_keys.length === config_kpis_keys.length && kpis_keys.every((val, index) => val === config_kpis_keys[index]))
          {
                for (i =0; i < this.config_kpis.length; i++) {
				  // Patch: if unit is not simple to display or is not convertable
                  let unit_to_display = "-";
				  if(this.config_kpis[i].unit !== "-"){
                  	 unit_to_display = this.helper.getUnitInfo(this.config_kpis[i].unit);
                     this.unit_kpi = unit_to_display["translate_unit"]
                     this.unit_kpi = this.unit_kpi.substring(this.unit_kpi.indexOf('.') + 1);
				  }
                  // Find index of kpi in result having same name than name from config
                  let kpi_index_in_result = this._kpis.map(function(e) { return e.name; }).indexOf(this.config_kpis[i].id);
                  var factor_to_convert_units = 1;
                  if (this._kpis[kpi_index_in_result].unit.toLowerCase() != this.config_kpis[i].unit) {
					//error TS2367: This condition will always return 'true' since the types 'string' and '(object: any) => boolean' have no overlap.
                    //if (unit_to_display != isNullOrUndefined){
                    if (unit_to_display != undefined){
                      factor_to_convert_units = unit_to_display["convert_factor_from"][this._kpis[kpi_index_in_result].unit.toLowerCase()]
                    }
                    else {
                      this._kpis[i].unit = "unit_error";
                      continue;
                    }
                  }
                  this._kpis[i].value *= factor_to_convert_units;
				  // Patch: if unit is not simple to display or is not convertable
				  if(this.config_kpis[i].unit !== "-" && this.config_kpis[i].unit !== "chf"){
                  this._kpis[i].unit = this.unit_kpi; //unit_to_display["translate_unit"];
                  [this._kpis[i].value, this._kpis[i].unit] = this.helper.convertTooBigValue(this._kpis[i].value, this._kpis[i].unit);
				  }
                }
                // this.loaderService.displayMainLoader(false)
                // this.setTitle('DASHBOARD.KPI.TITLE', { commune: this.selectedCity, date: this.dataFilters.year['name'] })
          }},
          (error) => {
            this._kpis = null;
            // Default title
            this.setTitle('DASHBOARD.KPI.DEFAUTTITLE', {});
          });
        }
      }
    }
  }
  getOrderedConfig() {
    if(!this.helper.isNullOrUndefined(this.config_kpis)) {
      return this.helper.getOrderedDictByOrderName(this.config_kpis)
    }
    return []
  }
  getKpiById(id) {
    var val = this._kpis.filter(x => x.name === id)[0];
    return val
  }
  getKpiTooltipTranslated(id) {
    uikit.tooltip('#kpi_' + id, { title: this.translateService.instant(`DASHBOARD.KPI.${id}.INFO`), pos: 'right' }).show();
  }
  setTitle(title, data) {
    this.title = title;
    this.titleData = data
  }
  filterKpisToDisplay(){
    let listOfKpisToUpdate = [];
    let updateKpisWithNewFilter = false;
    var i;
    for (i=0;i < this.kpis_from_config_file.length;i++){
      let checkIfKpi = true;
      for(const item in this.kpis_from_config_file[i]["filter-options"]){
        if (this.kpis_from_config_file[i]["filter-options"][item] != this.dataFilters[item]){
          checkIfKpi = false;
          break;
        }
      }
      if (checkIfKpi){
        listOfKpisToUpdate.push(this.kpis_from_config_file[i])
        updateKpisWithNewFilter = true
      }
    }
    this.config_kpis = listOfKpisToUpdate;

    return updateKpisWithNewFilter;
  }
}

