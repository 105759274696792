import { Injectable } from '@angular/core';


import { NavigationButton } from '../class';
import { navigationButtons } from './navigation-buttons.data';

import { SidePanelService } from '../../../features/side-panel';

import { Logger} from '../../../shared';


@Injectable()
export class NavigationBarService {

    constructor(private panelService: SidePanelService, private logger: Logger) {
    }

    toggleBar(button: any) {
      this.logger.log('button: ' + button);
      button.stateOpen = !button.stateOpen;
      if (button.enable) {
          if (button.buttonFunction === 'left') {
              this.panelService.openLeftPanel();
          }
      }
    }
    enableButton(id: string) {
      const button  =  this.getButtons().filter(x => x.id === id)[0];
      button.enable = true;
      button.stateOpen = true;
    }

    disableButton(id: string) {
      const button  =  this.getButtons().filter(x => x.id === id)[0];
      button.enable = false;
      button.stateOpen = false;
    }
    disableButtonsWithFunction(func: string) {
      const buttons  =  this.getButtons().filter(x => x.buttonFunction === func);
      buttons.forEach((button) => {
        button.enable = false;
        button.stateOpen = false;
      });
    }
    enableButtonsWithFunction(func: string) {
      const buttons  =  this.getButtons().filter(x => x.buttonFunction === func);
      buttons.forEach((button) => {
        button.enable = true;
        button.stateOpen = true;
      });
    }
    getButtons(): NavigationButton[] {
        return navigationButtons;
    }


}
