import { Component, OnInit, AfterContentInit } from '@angular/core';
import { LoaderService } from 'app/shared';
import { DataCollectionService } from 'app/shared/services/data-collection.service';
import { Helper } from 'app/shared/helper';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-web-frame',
  templateUrl: './web-frame.component.html',
  styleUrls: ['./web-frame.component.css']
})
export class WebFrameComponent implements OnInit, AfterContentInit {
  webframeurl = null;
  constructor(private dataCollectionService: DataCollectionService, private router: Router, private sanitizer: DomSanitizer) {
  }

  ngAfterContentInit(): void { }

  ngOnInit() {
    this.dataCollectionService.webframeExists().then(exist => {
      if (exist) {
        this.dataCollectionService.getConfigFile().then(data => {
          this.webframeurl = this.sanitizer.bypassSecurityTrustResourceUrl(data.webframe);
        })
      } else {
        this.router.navigateByUrl('/welcome');

      }
    })
  }

}
