import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/shared/helper';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-society-twokw',
  templateUrl: './society-twokw.component.html',
  styleUrls: ['./society-twokw.component.css']
})
export class SocietyTwokwComponent implements OnInit {
  @ViewChild('chartS2000W') canvas;
  @Input() type;
  @Input() chart_id;
  @Input() options;
  @Input() indicator;
  @Input() sample_data;
  @Input() chartData;
  @Input() value_type;
  @Input() unit;

  private yAxeLabel: String;
  private yAxes_def;
  //To change or improve
  private chart: Chart;
  public s2000wData: any;

  constructor(private helper: Helper, private translateService: TranslateService) { }

  ngOnInit() {
  }

  ngOnChanges(){
    this.createS2000WChart();
  }

  getTranslatedLabel(labelToTranslate) {
    return this.translateService.instant('DATA.' + labelToTranslate);
  }

  createS2000WChart(){
    this.resetChart();
    if (this.sample_data){
      this.s2000wData = this.getSampleData();
    }
    else {
      this.yAxeLabel = this.translateService.instant(`DASHBOARD.CHARTS.${ this.chart_id }.Y_LABEL_NAME_IND_EN`);
      // var unit_from_config = this.helper.getUnitIdFromFilterValue(this.value_type, this.unit['energy']);
      this.yAxeLabel += this.translateService.instant("UNITS.energy.inhabitant_kwh"); 
      if (this.chartData) {
        this.s2000wData = {labels : this.chartData['subsidies_labels']};
        this.s2000wData['datasets'] = this.chartData['subsidies_datasets'];
        this.options['scales']['yAxes'][0]['scaleLabel']['labelString'] = this.yAxeLabel;
        var i;
        for (i = 0; i < this.s2000wData['datasets'].length; i++) {
          this.s2000wData['datasets'][i]['label'] = this.getTranslatedLabel(this.s2000wData['datasets'][i]['label']);
        }
      }
      this.s2000wData = {
        labels: [
        1950,	1951,	1952,	1953,	1954,	1955,	1956,	1957,	1958,	1959,	1960,	1961,	1962,	1963,	1964,	1965,	1966,	1967,	1968,	1969,	1970,	1971,	1972,	1973,	1974,	1975,	1976,	1977,	1978,	1979,	1980,	1981,	1982,	1983,	1984,	1985,	1986,	1987,	1988,	1989,	1990,	1991,	1992,	1993,	1994,	1995,	1996,	1997,	1998,	1999,	2000,	2001,	2002,	2003,	2004,	2005,	2006,	2007,	2008,	2009,	2010,	2011,	2012,	2013,	2014,	2015,	2016,	2017,	2018,	2019,	2020,	2021,	2022,	2023,	2024,	2025,	2026,	2027,	2028,	2029,	2030,	2031,	2032,	2033,	2034,	2035,	2036,	2037,	2038,	2039,	2040,	2041,	2042,	2043,	2044,	2045,	2046,	2047,	2048,	2049,	2050,	2051,	2052,	2053,	2054,	2055,	2056,	2057,	2058,	2059,	2060,	2061,	2062,	2063,	2064,	2065,	2066,	2067,	2068,	2069,	2070,	2071,	2072,	2073,	2074,	2075,	2076,	2077,	2078,	2079,	2080,	2081,	2082,	2083,	2084,	2085,	2086,	2087,	2088,	2089,	2090,	2091,	2092,	2093,	2094,	2095,	2096,	2097,	2098,	2099,	2100],
        datasets: [
          {backgroundColor: "#A7A7A7", borderColor: "#00000000", data: [
            1200,,,,,,,,,,,,,,,,,,,,,,,,, 4000,,,,,,,,,,,,,,,,,,,,,,,,, 5400,,,,,,,,,,,,,,,,,,,,,,,,, 3700,,,,,,,,,,,,,,,,,,,,,,,,, 2000,,,,,,,,,,,,,,,,,,,,,,,,, 1400,,,,,,,,,,,,,,,,,,,,,,,,, 500],
            label: "Suisse - énergie non renouvelable", stack:"evol_2000W", yAxisID: 'evol_2000W', spanGaps: true},
          {backgroundColor: "#57B0E3", borderColor: "#00000000", data: [
            600,,,,,,,,,,,,,,,,,,,,,,,,, 600,,,,,,,,,,,,,,,,,,,,,,,,, 800,,,,,,,,,,,,,,,,,,,,,,,,, 1400,,,,,,,,,,,,,,,,,,,,,,,,, 1500,,,,,,,,,,,,,,,,,,,,,,,,, 1500,,,,,,,,,,,,,,,,,,,,,,,,, 1500],
            label: "Suisse - énergie renouvelable / déchets", stack:"evol_2000W", yAxisID: 'evol_2000W', spanGaps: true},
          {backgroundColor: "#000000", borderColor: "#000000", data: [
            NaN,,,,,,,,,,,,,,,,,,,,,,,,, NaN,,,,,,,,,,,,,,,,,,,,,,,,, NaN,,,,,,,,,,,,, 4700,,,,,,,,,,,, NaN,,,,, 1800,,,,,,,,,,,,,,,,,,,, 800,,,,,,,,,,,,,,,,,,,,,,,,, 650,,,,,,,,,,,,,,,,,,,,,,,,, 500],
            label: "Delémont - objectifs - énergie non ren.", fill:false, stack: "City", yAxisID: 'City', spanGaps: true},
          {borderColor: "#2F5597", data: [
            NaN,,,,,,,,,,,,,,,,,,,,,,,,, NaN,,,,,,,,,,,,,,,,,,,,,,,,, NaN,,,,,,,,,,,,, 1100,,,,,,,,,,,, NaN,,,,, 1600,,,,,,,,,,,,,,,,,,,, 2050,,,,,,,,,,,,,,,,,,,,,,,,, 1750,,,,,,,,,,,,,,,,,,,,,,,,, 1500],
            label: "Delémont - objectifs - énergie ren. / déchets", fill:false, stack: "City", yAxisID: 'City', spanGaps: true},
          {backgroundColor: "#000000", borderColor: "#000000", data: [
            NaN,,,,,,,,,,,,,,,,,,,,,,,,, NaN,,,,,,,,,,,,,,,,,,,,,,,,, NaN,,,,,,,,,,,,, 4700,,,,,,,3850,,,,, NaN,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,],
            label: "Delémont - données historiques - énergie non ren.", fill:false, stack: "City_meas", yAxisID: 'City_meas', spanGaps: true, borderDash: [4,2]},
          {borderColor: "#2F5597", data: [
            NaN,,,,,,,,,,,,,,,,,,,,,,,,, NaN,,,,,,,,,,,,,,,,,,,,,,,,, NaN,,,,,,,,,,,,, 1100,,,,,,,1250,,,,, NaN,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,],
            label: "Delémont - données historiques - énergie ren. / déchets", fill:false, stack: "City_meas", yAxisID: 'City_meas', spanGaps: true, borderDash: [4,2]},
          {backgroundColor: "#B02D37", borderColor: "#B02D37", data: [
            2000,,,,,,,,,,,,,,,,,,,,,,,,, NaN,,,,,,,,,,,,,,,,,,,,,,,,, NaN,,,,,,,,,,,,,,,,,,,,,,,,, NaN,,,,,,,,,,,,,,,,,,,,,,,,, NaN,,,,,,,,,,,,,,,,,,,,,,,,, NaN,,,,,,,,,,,,,,,,,,,,,,,,, 2000],
            label: "2000 W", fill:false, stack: "2000Watt", yAxisID: '2000Watt', spanGaps: true}
        ]
      }
    }
    this.yAxes_def = [{
      stacked: true,
      display: true,
      id: "evol_2000W",
      scaleLabel: {
        display: true,
        labelString: this.yAxeLabel,
        fontColor: 'black',
        fontSize: 14
      },
      ticks: {
        fontColor: 'black',
        fontSize: 12,
        callback: value => {
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")
        },
        beginAtZero: true,
        steps: 7,
        stepValue: 1000,
        max: 7000
      }
    }];
    this.yAxes_def.push({
      stacked: true,
      display: false,
      id: "City",
      scaleLabel: {
        display: true,
        labelString: this.yAxeLabel,
        fontColor: 'black',
        fontSize: 14
      },
      ticks: {
        beginAtZero: true,
        steps: 7,
        stepValue: 1000,
        max: 7000
      }
    });
    this.yAxes_def.push({
      stacked: true,
      display: false,
      id: "City_meas",
      scaleLabel: {
        display: true,
        labelString: this.yAxeLabel,
        fontColor: 'black',
        fontSize: 14
      },
      ticks: {
        beginAtZero: true,
        steps: 7,
        stepValue: 1000,
        max: 7000
      }
    });
    this.yAxes_def.push({
      stacked: false,
      display: false,
      id: "2000Watt",
      scaleLabel: {
        display: true,
        labelString: this.yAxeLabel,
        fontColor: 'black',
        fontSize: 14
      },
      ticks: {
        beginAtZero: true,
        steps: 7,
        stepValue: 1000,
        max: 7000
      }
    });
    this.options.scales['xAxes'] = [{
      ticks: {
        align: 'center',
        step: 6,
        stepValue: 25
      }
    }];
    this.options.scales.yAxes = this.yAxes_def;
    this.options.scaleShowVerticalLines = false;
    this.options.maintainAspectRatio = true;
    // Define behavior of tooltip for the chart
    this.options.tooltips.callbacks.label = (item, data) => {
      let unit;
      let translate_var = this.value_type
      //unit = this.translateService.instant(`UNITS.${ unit_from_config["translate_unit"] }`);   
      unit = this.translateService.instant("UNITS.energy.inhabitant_kwh");
      return [`${data.datasets[item.datasetIndex].label}: `, `${this.helper.getThousandSeparatorFromNumber(item.yLabel)} `
      + unit]
    }
    const ctx = this.canvas.nativeElement.getContext('2d');
    /* Chart.Legend.prototype.afterFit = function(){
      this.height = this.height + 10;
    } */
    this.chart = new Chart(ctx, {
      type: this.type,
      data: this.s2000wData,
      options: this.options
    });
  }

  resetChart() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = void 0;
    }
  }

  getSampleData() {
    let networksChartLabels = [2017, 2018, 2019, 2020];
    let gazData = [104490000, 98973204, 92026299, 90000000];
    let dhnData = [4500000, 5475000, 6450000, 8250000];
    var dcnData = [0, 10000000, 50000000, 50000000];

    var sampleData = {
      labels: networksChartLabels,
    }
    var areaChartDatasets = [
      {
        label: 'Réseau de gaz',
        data: gazData,
        stack: "gaz",
        backgroundColor: 'rgba(50, 50, 50, 0.3)',
        borderColor: 'rgba(50, 50, 50)',
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'mwh'
      },
      {
        label: 'Réseaux CAD',
        data: dhnData,
        stack: "dhn",
        backgroundColor: 'rgba(164, 24, 211, 0.3)',
        borderColor: 'rgba(164, 24, 211)',
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'mwh'
      }
    ];
    sampleData['datasets']= areaChartDatasets
    return sampleData;
  }

}