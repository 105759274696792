import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'thounsandSeparator'
})

export class ThounsandSeparatorPipe implements PipeTransform {
    transform(value: string, args: any[]): string {
        if (value) {
          return value.split(',').join('\'');
        }
        return '';
    }
}
