import { Helper } from './shared/helper';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DecimalPipe} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {HttpModule} from '@angular/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, ApplicationRef} from '@angular/core';
import { HttpClientModule, HttpClient} from '@angular/common/http';
import { MatTabsModule } from '@angular/material/tabs';
import { AppComponent } from './app.component';
import { AppRoutingModule} from './app-routing.module';
import { DashboardComponent, WebFrameComponent, WelcomeComponent } from './pages';
import { Logger, LoaderService, ToasterService, GlobalErrorHandler} from './shared/';
import { LeftSideComponent, SidePanelService, SideComponent } from './features/side-panel/';
import { NavigationBarComponent } from './pages/nav';
import { FilterOptionsComponent } from './features/filter-options/filter-options.component';
import { ChartsComponent } from 'app/features/charts/charts.component';
import { KpiComponent } from 'app/features/kpi/kpi.component';
import { FilterOptionService } from 'app/features/filter-options/filter-options.service';
import { DataCollectionService } from 'app/shared/services/data-collection.service';
import { NavigationBarService } from 'app/pages/nav/service/navigation-bar.service';
import { ChartComponent } from './features/charts/component/chart.component';
import { ChartsService } from 'app/features/charts/charts.service';
import { TranslateModule, TranslateLoader, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ThounsandSeparatorPipe } from './shared/pipes/thounsand-separator.pipe';
import { RoundNumberPipe } from './shared/pipes/round-number.pipe';
import { LegendComponent } from './features/charts/legend/legend.component';
import { MultiLegendComponent } from './features/charts/multi-legend/multi-legend.component';
import { RegisterComponent } from './pages/register';
import {MultiTranslateHttpLoader} from "ngx-translate-multi-http-loader";
import { AreaChartComponent } from './features/charts/area-chart/area-chart.component';
import { NetworksChartComponent } from './features/charts/networks-chart/networks-chart.component';
import { FuelTypeVehiclesComponent } from './features/charts/fuel-type-vehicles/fuel-type-vehicles.component';
import { ElectricityConsChartComponent } from './features/charts/electricity-cons-chart/electricity-cons-chart.component';
import { AreaMeasuredmeasuredBarchartComponent } from './features/charts/area-measuredmeasured-barchart/area-measuredmeasured-barchart.component';
import { ActiosChartsComponent } from './features/charts/actios-charts/actios-charts.component';
import { SubsidiesChartComponent } from './features/charts/subsidies-chart/subsidies-chart.component';
import { PublicBdgChartComponent } from './features/charts/public-bdg-chart/public-bdg-chart.component';
import { RenewableProdChartComponent } from './features/charts/renewable-prod-chart/renewable-prod-chart.component';
import { SocietyTwokwComponent } from './features/charts/society-twokw/society-twokw.component';
import { ElectricityProductionChartComponent } from './features/charts/electricity-production-chart/electricity-production-chart.component';
import { HeaterSubstitutionChartComponent } from './features/charts/heater-substitution-chart/heater-substitution-chart.component';
import { PvTargetValueChartComponent } from './features/charts/pv-target-value-chart/pv-target-value-chart.component';
import { NbVehicleTypesComponent } from './features/charts/nb-vehicle-types/nb-vehicle-types.component';
import { PublicConsumptionComponent } from './features/charts/public-consumption/public-consumption.component';

export function createTranslateLoader(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    {prefix: "/assets/translate/default/", suffix: ".json"},
    {prefix: "/assets/translate/custom/", suffix: ".json"},
  ]);
}
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LeftSideComponent,
    NavigationBarComponent,
    WebFrameComponent,
    WelcomeComponent,
    FilterOptionsComponent,
    KpiComponent,
    ChartsComponent,
    ChartComponent,
    ThounsandSeparatorPipe,
    RoundNumberPipe,
    LegendComponent,
    MultiLegendComponent,
    SideComponent,
    RegisterComponent,
    AreaChartComponent,
    NetworksChartComponent,
    FuelTypeVehiclesComponent,
    ElectricityConsChartComponent,
    AreaMeasuredmeasuredBarchartComponent,
    ActiosChartsComponent,
    SubsidiesChartComponent,
    PublicBdgChartComponent,
    RenewableProdChartComponent,
    SocietyTwokwComponent,
    ElectricityProductionChartComponent,
    HeaterSubstitutionChartComponent,
    PvTargetValueChartComponent,
    NbVehicleTypesComponent,
    PublicConsumptionComponent
  ],
  imports: [
    HttpModule,
    HttpClientModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    MatTabsModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
      }
  })
  ],
  providers: [
    Logger,
    LoaderService,
    ToasterService,
    GlobalErrorHandler,
    DecimalPipe,
    SidePanelService,
    NavigationBarService,
    FilterOptionService,
    DataCollectionService,
    Helper,
    ChartsService,
    HttpClientModule,
    ThounsandSeparatorPipe,
    TranslatePipe
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
