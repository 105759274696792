import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Logger } from 'app/shared';
import { Helper } from 'app/shared/helper';
import { Chart } from 'chart.js';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-area-chart',
  templateUrl: './area-chart.component.html',
  styleUrls: ['./area-chart.component.css']
})
export class AreaChartComponent implements OnInit {
  @ViewChild('chartArea') canvas;
  @Input() type;
  @Input() chart_id;
  @Input() options;
  @Input() unit;
  @Input() chartData;
  @Input() value_type;
  @Input() indicator;
  @Input() sample_data;
  @Input() gas_debt;
  @Input() obj_list;
  
  public areaChartOptions: { responsive: boolean; };
  public areaChartLabels: number[];
  public areaChartData: any;
  private chart: Chart;
  private yAxes_def;
  private yAxeLabel: string;
  private areaChartDatasets; 
  private show_gas_debt: boolean;
  constructor(private logger: Logger, private helper: Helper, private translateService: TranslateService) { }

  ngOnInit() {
    this.logger.log('StrategyChartComponent/ngOnInit()');
  }

  ngOnChanges(changes){
    if (!this.helper.isNullOrUndefined(changes.chartData) && !this.helper.isNullOrUndefined(changes.chartData.currentValue)) {
      this.defineIfShowgasDebt();
      this.createAreaChart();
    }
  }

  getTranslatedLabel(labelToTranslate) {
    return this.translateService.instant('DATA.' + labelToTranslate)
  }

  createAreaChart() {
    this.resetChart();
    if (this.sample_data){
      let sum_instead_of_details = false;
      this.areaChartData = this.getSampleData(sum_instead_of_details);
    }
    else {
      let sum_instead_of_details = false;
      this.areaChartData = {labels : this.chartData[0]["strategy_label"]};
      this.areaChartData['datasets'] = this.chartData[0]["strategy_data"];
      // this.areaChartData['lineAtIndex'] = this.chartData["line_index"];
      var i;
      for (i = 0; i < this.areaChartData['datasets'].length; i++) {
        this.areaChartData['datasets'][i]['label'] = this.getTranslatedLabel(this.areaChartData['datasets'][i]['label']);
        // this.areaChartData['datasets'][i]['label'] = "TEST";
      }
    }
    this.areaChartOptions = {
      responsive: true
    }
    
    // DEFINE Y LABEL WITH UNIT
    if (this.indicator == "energy"){var y_label_type = "Y_LABEL_NAME"} else if (this.indicator == "co2") {var y_label_type = "Y_LABEL_NAME_CO2"}
    this.yAxeLabel = this.translateService.instant(`DASHBOARD.CHARTS.${ this.chart_id }.${ y_label_type }`);
    var unit_from_config = this.helper.getUnitIdFromFilterValue(this.value_type, this.unit[this.indicator]);
    this.yAxeLabel += this.translateService.instant(`UNITS.${ unit_from_config["translate_unit"] }`);
    
    // Define behavior of tooltip label for the chart
    this.options.tooltips.callbacks.label = (item, data) => {
      let unit;
      unit = this.translateService.instant(`UNITS.${ unit_from_config["translate_unit"] }`);
      return [`${data.datasets[item.datasetIndex].label} : ${this.helper.getThousandSeparatorFromNumber(item.yLabel)} `
      + unit]
    }

    this.yAxes_def = [{
      stacked: true,
      display: true,
      id: "mwh",
      scaleLabel: {
        display: true,
        labelString: this.yAxeLabel,
        fontColor: 'black',
        fontSize: 14
      },
      ticks: {
        fontColor: 'black',
        fontSize: 12,
        callback: value => {
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
        }
      }
    }]
    this.yAxes_def.push({
      stacked: true,
      display: false,
      id: "test",
      scaleLabel: {
        display: true,
        labelString: `test`,
        fontColor: 'black',
        fontSize: 14
      },
      ticks: {
        fontColor: 'black',
        fontSize: 12
      }
    })
    if (this.show_gas_debt){
      this.yAxes_def.push({
        stacked: false,
        display: true,
        id: "debt-CHF",
        position: 'right',
        scaleLabel: {
          display: true,
          gridLines: {
            color: "red"
          },
          labelString: 'Dette du gaz en CHF',
          fontColor: 'red',
          fontSize: 14
        },
        ticks: {
          fontColor: 'red',
          fontSize: 12
        }
      })
    }

    if (this.obj_list && this.obj_list.length > 0){
      this.yAxes_def.push({
        stacked: false,
        display: false,
        id: "obj_mwh",
        position: 'right',
        scaleLabel: {
          display: false,
          labelString: '',
          fontColor: 'red',
          fontSize: 14
        },
        ticks: {
          suggestedMin: 0,
          suggestedMax: 250000,
          fontColor: 'red',
          fontSize: 12
        }
      })
    }
    this.options.responsive = true;
    this.options['maintainAspectRatio'] = true;
    this.options.scales.yAxes = this.yAxes_def;
    const ctx = this.canvas.nativeElement.getContext('2d');
    this.chart = new Chart(ctx, {
      type: this.type,
      data: this.areaChartData,
      options: this.options
    });
    
  }
  
  resetChart() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = void 0;
    }
  }

  defineIfShowgasDebt(){
    if (!this.obj_list && this.gas_debt){
      this.show_gas_debt = true;
    }
    if (this.obj_list && this.obj_list.length > 0){
      this.show_gas_debt = true;
    }
    else if (this.obj_list && this.obj_list.length == 0 && this.gas_debt){
      this.show_gas_debt = true;
    }
  }

  getSampleData(sumed_values) {
    let areaChartLabels = [2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021,	2022,	2023,	2024,	2025,	2026,	2027,	2028,	2029,	2030,	2031,	2032,	2033,	2034,	2035,	2036,	2037,	2038,	2039,	2040,	2041,	2042,	2043,	2044,	2045,	2046,	2047,	2048,	2049,	2050];
    var sampleData = {
      labels: areaChartLabels,
    } 
    if (!sumed_values){
      this.areaChartDatasets = [
        {
          label: 'Mazout',
          pointRadius: 0,
          data: [172000,	161882,	151765,	141647,	131529,	121412,	111294,	101176,	91059,	80941,	70824,	60706,	50588,	40471,	30353,	20235,	10118,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0, 0],
          backgroundColor: [
              'rgba(50, 50, 50)'
          ],
          borderColor: [
            'rgba(50, 50, 50)'
          ],
          borderWidth: 1,
          lineTension: 0,
          yAxisID: 'mwh'
        },
        {
          label: 'Autre individuel ren.',
          pointRadius: 0,
          data: [1000,	7706,	14412,	21118,	27824,	34529,	41235,	47941,	54647,	61353,	68059,	74765,	81471,	88176,	94882,	101588,	108294,	115000,	116316,	117632,	118947,	120263,	121579,	122895,	124211,	125526,	126842,	128158,	129474,	130789,	132105,	133421,	134737,	136053,	137368,	138684,	140000],
          backgroundColor: [
              'rgba(0, 176, 80)'
          ],
          borderColor: [
            'rgba(0, 176, 80)'
          ],
          borderWidth: 1,
          lineTension: 0,
          yAxisID: 'mwh'
        },
        {
          label: 'Gaz y.c. appoint futur CAD',
          pointRadius: 0,
          data: [45000, 49706, 54412,	59118,	63824,	68529,	73235,	77941,	82647,	87353,	92059,	96765,	101471,	106176,	110882,	115588,	120294,	125000,	120000,	115000,	110000,	105000,	100000,	95000,	90000,	85000,	80000,	75000,	70000,	65000,	60000,	55000,	50000,	45000,	40000,	35000,	30000],
          backgroundColor: [
            'rgba(100, 100, 100)'
          ],
          borderColor: [
            'rgba(100, 100, 100)'
          ],
          borderWidth: 1,
          lineTension: 0,
          yAxisID: 'mwh'
        },
        {
          label: 'Futur CAD',
          pointRadius: 0,
          data: [0, 0,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0,	2895,	5789,	8684,	11579,	14474,	17368,	20263,	23158,	26053,	28947,	31842,	34737,	37632,	40526,	43421,	46316,	49211,	52105,	55000],
          backgroundColor: [
            'rgba(51, 204, 51)'
          ],
          borderColor: [
            'rgba(51, 204, 51)'
          ],
          borderWidth: 1,
          lineTension: 0,
          yAxisID: 'mwh'
        },
        {
          label: 'Solaire thermique',
          pointRadius: 0,
          data: [0,	294,	588,	882,	1176,	1471,	1765, 2059,	2353,	2647,	2941,	3235,	3529,	3824,	4118,	4412,	4706,	5000,	5526,	6053,	6579,	7105,	7632,	8158,	8684,	9211,	9737,	10263,	10789,	11316,	11842,	12368,	12895,	13421,	13947,	14474,	15000],
          backgroundColor: [
              'rgba(255, 204, 51)'
          ],
          borderColor: [
              'rgba(255, 204, 51)'
          ],
          borderWidth: 1,
          lineTension: 0,
          yAxisID: 'mwh'
        },
        {
          label: 'Hydraulique',
          pointRadius: 0,
          data: [0,	59,	118,	176,	235,	294,	353, 412,	471,	529,	588,	647,	706,	765,	824,	882,	941,	1000,	1000,	1000,	1000,	1000,	1000,	1000,	1000,	1000,	1000,	1000,	1000,	1000,	1000,	1000,	1000,	1000,	1000,	1000,	1000],
          backgroundColor: [
              'rgba(0, 153, 255)'
          ],
          borderColor: [
              'rgba(0, 153, 255)'
          ],
          borderWidth: 1,
          lineTension: 0,
          yAxisID: 'mwh'
        },
        {
          label: 'Certificats hydrauliques',
          pointRadius: 0,
          data: [83000,	83000,	83000,	83000,	83000,	83000,	83000, 83000,	83000,	83000,	83000,	83000,	83000,	83000,	83000,	83000,	83000,	83000,	83000,	83000,	83000,	83000,	83000,	83000,	83000,	83000,	83000,	83000,	83000,	83000,	83000,	83000,	83000,	83000,	83000,	83000,	83000],
          backgroundColor: [
              'rgba(108, 196, 250)'
          ],
          borderColor: [
              'rgba(108, 196, 250)'
          ],
          borderWidth: 1,
          lineTension: 0,
          yAxisID: 'mwh'
        },
        {
          label: 'Réseau hors SID',
          pointRadius: 0,
          data: [20000,	20000,	20000,	20000,	20000,	20000,	20000, 20000,	20000,	20000,	20000,	20000,	20000,	20000,	20000,	20000,	20000,	20000,	20000,	20000,	20000,	20000,	20000,	20000,	20000,	20000,	20000,	20000,	20000,	20000,	20000,	20000,	20000,	20000,	20000,	20000,	20000],
          backgroundColor: [
              'rgba(249, 172, 109)'
          ],
          borderColor: [
              'rgba(249, 172, 109)'
          ],
          borderWidth: 1,
          lineTension: 0,
          yAxisID: 'mwh'
        },
        {
          label: 'Photovoltaïque',
          pointRadius: 0,
          data: [500,	1000,	1500,	2000,	2500,	3000,	3500, 4000,	4500,	5000,	5500,	6000,	6500,	7000,	7500,	8000,	8500,	9000,	9500,	10000,	10500,	11000,	11500,	12000,	12500,	13000,	13500,	14000,	14500,	15000,	15500,	16000,	16500,	17000,	17500,	18000,	18500],
          backgroundColor: [
              'rgba(246, 249, 109)'
          ],
          borderColor: [
              'rgba(246, 249, 109)'
          ],
          borderWidth: 1,
          lineTension: 0,
          yAxisID: 'mwh'
        },
        {
          label: 'Eolien',
          pointRadius: 0,
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 500,	1000,	1500,	2000,	2500,	3000,	3500, 4000,	4500,	5000,	5500,	6000,	6500,	7000,	7500,	8000,	8500,	9000,	9500,	10000,	10500],
          backgroundColor: [
              'rgba(153, 102, 255)'
          ],
          borderColor: [
              'rgba(153, 102, 255)'
          ],
          borderWidth: 1,
          lineTension: 0,
          yAxisID: 'mwh'
        }
      ];
      if (this.show_gas_debt){
        this.areaChartDatasets.push({
          label: 'Dette du gaz',
          pointRadius: 0,
          data: [6500000,	6117647,	5735294,	5352941,	4970588,	4588235,	4205882,	3823529,	3441176,	3058824,	2676471,	2294118,	1911765,	1529412,	1147059,	764706,	382353,	0
            ,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0, 0],
          type: 'line',
          fill: false,
          fillStyle: 'rgba(50, 50, 50, 0)',
          borderColor: [
            'red'
          ],
          borderWidth: 2,
          borderDash: [5, 8],
          lineTension: 0,
          yAxisID: 'debt-CHF'
        })
      }
      if (this.obj_list && this.obj_list.length > 0 && this.areaChartDatasets){
        this.obj_list.forEach(this.objDataSet, this);
      }
      sampleData['datasets']= this.areaChartDatasets
    }
    else {
      var areaChartTotDataset = [{
        label: 'Total',
        pointRadius: 0,
        data: [172000,	161882,	151765,	141647,	131529,	121412,	111294,	101176,	91059,	80941,	70824,	60706,	50588,	40471,	30353,	20235,	10118,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0,	0, 0],
        backgroundColor: [
            'blue'
        ],
        borderColor: [
          'blue'
        ],
        borderWidth: 1,
        lineTension: 0,
        yAxisID: 'mwh'
      }];
      sampleData['datasets']= areaChartTotDataset
    }
    return sampleData;
  }

  objDataSet(item){
    var obj_param = {
      /* CoCen: {
        label: "Obj. Cocen",
        obj_data: [10000,	10000,	10000,	10000,	10000,	10000,	10000,	10000,	10000,	10000,
          10000,	10000,	10000,	10000,	10000,	10000,	10000,	10000,	10000,	10000,	10000,
          10000,	10000,	10000,	10000,	10000,	10000,	10000,	10000,	10000,	10000,	10000,
          10000,	10000,	10000,	10000,	10000, 10000],
        color: 'rgb(255,255,255)'
      }, */
      SE2050: {
        label: "Obj. Stratégie énergétique 2050",
        obj_data: [20000,	20000,	20000,	20000,	20000,	20000,	20000,	20000,	20000,	20000,
            20000,	20000,	20000,	20000,	20000,	20000,	20000,	20000,	20000,	20000,	20000,
            20000,	20000,	20000,	20000,	20000,	20000,	20000,	20000,	20000,	20000,	20000,	
            20000,	20000,	20000,	20000,	20000, 20000],
        color: 'rgb(255,255,255)'
      },
      S2000W: {
        label: "Obj. Société 2000W",
        obj_data: [50000,	50000,	50000,	50000,	50000,	50000,	50000,	50000,	50000,	50000,	
          50000,	50000,	50000,	50000,	50000,	50000,	50000,	50000,	50000,	50000,	50000,	
          50000,	50000,	50000,	50000,	50000,	50000,	50000,	50000,	50000,	50000,	50000,	
          50000,	50000,	50000,	50000,	50000, 50000],
        color: 'rgb(255,255,255)'
      }  
    }

    this.areaChartDatasets.push({
      label: obj_param[item["id"]].label,
      pointRadius: 0,
      data: obj_param[item["id"]].obj_data,
      fill: false,
      backgroundColor: [
        item["color"]
        ],
      borderColor: [
        item["color"]
        ],
      borderWidth: 2,
      lineTension: 0,
      yAxisID: 'obj_mwh'
    })
  }
}
