import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/shared/helper';
import { Logger } from 'app/shared/services/logger.service';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-fuel-type-vehicles',
  templateUrl: './fuel-type-vehicles.component.html',
  styleUrls: ['./fuel-type-vehicles.component.css']
})

export class FuelTypeVehiclesComponent implements OnInit {
  @ViewChild('chartFuelVehicles') canvas;
  @Input() type;
  @Input() chart_id;
  @Input() options;
  @Input() indicator;
  @Input() sample_data;
  @Input() show_network_mix
  @Input() chartData;
  @Input() value_type;
  @Input() unit;  
  
  public areaChartOptions: { responsive: boolean; };
  public areaChartLabels: number[];
  public fuelvehiclesData: any;
  private chart: Chart;
  private yAxes_def;
  private xAxes_def;
  private fuelvehiclesDatasets;
  private yAxeLabel_ind: string;
  private yAxeLabel_amount: string;
  
  constructor(private logger: Logger, private helper: Helper, private translateService: TranslateService) { }

  ngOnInit() {
  }

  ngOnChanges(){
    this.createFuelvehiclesChart();
  }

  getTranslatedLabel(labelToTranslate) {
    return this.translateService.instant('DATA.' + labelToTranslate);
  }

  createFuelvehiclesChart() {
    this.resetChart();
    if (this.sample_data){
      this.fuelvehiclesData = this.getSampleData();
    }
    else {
      let sum_instead_of_details = false;
      if (this.chartData) {
        this.fuelvehiclesData = {labels : this.chartData["vehicle_fuel_label"]};
        this.fuelvehiclesData['datasets'] = this.chartData["vehicle_fuel_data"];
        var i;
        for (i = 0; i < this.fuelvehiclesData['datasets'].length; i++) {
          this.fuelvehiclesData['datasets'][i]['label'] = this.getTranslatedLabel(this.fuelvehiclesData['datasets'][i]['label']);
          if (this.fuelvehiclesData['datasets'][i]['yAxisID'] == 'ind' && this.value_type != 'pourcent' && this.indicator == 'energy'){
            var j;
            for (j = 0; j< this.fuelvehiclesData['datasets'][i]['data'].length; j++){
              this.fuelvehiclesData['datasets'][i]['data'][j] *= 1000;
            }
          }
          // this.areaChartData['datasets'][i]['label'] = "TEST";
        }
      }
    }
    this.areaChartOptions = {
      responsive: true
    }
    // DEFINE Y LABEL WITH UNIT
    if (this.indicator == "energy"){var y_label_type = "Y_LABEL_NAME_IND_EN"} else if (this.indicator == "co2") {var y_label_type = "Y_LABEL_NAME_IND_CO2"}
    this.yAxeLabel_ind = this.translateService.instant(`DASHBOARD.CHARTS.${ this.chart_id }.${ y_label_type }`);
    this.yAxeLabel_amount = this.translateService.instant(`DASHBOARD.CHARTS.${ this.chart_id }.Y_LABEL_NAME`);
    var unit_from_config = this.helper.getUnitIdFromFilterValue(this.value_type, this.unit[this.indicator]);
    var amount_unit = this.helper.getNumberUnitIdFromFilterValue(this.value_type);
    this.yAxeLabel_amount += this.translateService.instant(`UNITS.${ amount_unit["translate_unit"] }`);
    this.yAxeLabel_ind += this.translateService.instant(`UNITS.${ unit_from_config["translate_unit"] }`);
    // Define behavior of tooltip for the chart
    this.options.tooltips.callbacks.label = (item, data) => {
      let unit;
      let translate_var = this.value_type
      if (this.value_type == 'pourcent'){
        unit = "%";
      }
      else {
        if (data.datasets[item.datasetIndex]['yAxisID']=='ind'){
          if(this.indicator == "energy"){unit = "kWh"} else if (this.indicator == "co2"){unit = "tCO2"}
          // this.translateService.instant("UNITS....)"
        }
        else if (data.datasets[item.datasetIndex]['yAxisID']=='nb'){unit = "véhic."}
        if(unit_from_config['translate_unit'] == "energy.inhabitant_kwh" || unit_from_config['translate_unit']  == "ghg.inhabitant_ton"){unit += "/ hab."}
      }      
     /*  else {
        translate_var = unit + '.energy_absolute';
      } */
      return [`${data.datasets[item.datasetIndex].label} :`, `${this.helper.getThousandSeparatorFromNumber(item.yLabel)} `
      + unit]
    }
    const ctx = this.canvas.nativeElement.getContext('2d');
    this.options.legend = {
      onClick: (e) => e.stopPropagation()
    }
    this.options.scales.yAxes[0]['scaleLabel'] = {
        display: true,
        labelString: this.yAxeLabel_amount,
        fontColor: 'black',
        fontSize: 14
    };
    this.options.scales.yAxes[1]['scaleLabel'] = {
      display: true,
      labelString: this.yAxeLabel_ind,
      fontColor: 'black',
      fontSize: 14
    };
    this.options.scales.yAxes[0].stacked = true;
    this.options.scales.xAxes = [{
      stacked: true,
    }];
    this.chart = new Chart(ctx, {
      type: this.type,
      data: this.fuelvehiclesData,
      options: this.options,
      // options: {
      //   responsive: true,
      //   scales: {
      //     yAxes: this.yAxes_def,
      //   }
      // }
    });
  }
  
  resetChart() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = void 0;
    }
  }

  getSampleData() {
    let fuelvehiclesChartLabels = [2010, 2014, 2018];
    let totData = [9365, 10252, 9443]
    let gasolineData = [7500, 8000, 6500];
    let dieselData = [1850, 1950, 1940];
    let elecData = [15, 300, 1000];
    let gazData = [0, 2, 2];
    let h2Data = [0, 0, 1];
    
    var sampleData = {
      labels: fuelvehiclesChartLabels,
    }
    if (!this.show_network_mix) {
      this.fuelvehiclesDatasets = [
        {
          label: 'Total',
          data: totData,
          stack: "total",
          backgroundColor: 'rgba(50, 50, 50, 0.3)',
          borderColor: 'rgba(50, 50, 50)',
          borderWidth: 1,
          lineTension: 0,
          yAxisID: 'nb'
        },
        {
          label: 'Essence',
          data: gasolineData,
          stack: "gasoline",
          backgroundColor: 'rgba(51, 51, 0, 0.3)',
          borderColor: 'rgba(51, 51, 0)',
          borderWidth: 1,
          lineTension: 0,
          yAxisID: 'nb'
        },
        {
        label: 'Diesel',
          data: dieselData,
          stack: "diesel",
          backgroundColor: 'rgba(102, 0, 0, 0.3)',
          borderColor: 'rgba(102, 0, 0)',
          borderWidth: 1,
          lineTension: 0,
          yAxisID: 'nb'
        },
        {
          label: 'Véhicules électriques',
          data: elecData,
          stack: "elec",
          backgroundColor: 'rgba(51, 153, 255, 0.3)',
          borderColor: 'rgba(51, 153, 255)',
          borderWidth: 1,
          lineTension: 0,
          yAxisID: 'nb'
        },
        {
          label: 'Véhicules à gaz',
          data: gazData,
          stack: "gas",
          backgroundColor: 'rgba(255, 153, 0, 0.3)',
          borderColor: 'rgba(255, 153, 0)',
          borderWidth: 1,
          lineTension: 0,
          yAxisID: 'nb'
        },      
        {
          label: 'Véhicules à hydrogène',
          data: h2Data,
          stack: "h2",
          backgroundColor: 'rgba(102, 204, 204, 0.3)',
          borderColor: 'rgba(102, 204, 204)',
          borderWidth: 1,
          lineTension: 0,
          yAxisID: 'nb'
        }
      ];
    }
    sampleData['datasets'] = this.fuelvehiclesDatasets
    return sampleData;
  }
}
