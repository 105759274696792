

import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'roundNumber'
})
export class RoundNumberPipe implements PipeTransform {
    transform(value, args: any[]): string {
        let factor = 100;
        if (value >= 10) {
            factor = 1;
        }
        return "" + Math.round(value * factor) / factor;
    }
}
