import { Injectable } from "@angular/core";
import { Logger } from "./services";
import { DecimalPipe } from "@angular/common";
import { ColdObservable } from "rxjs/internal/testing/ColdObservable";

@Injectable()
export class Helper {
  constructor(private logger: Logger) {}
  validURL(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }
  // check if unfined or not
  isNullOrUndefined(x: any) {
    let result = false;
    if (x == null) {
      result = true;
    }

    if (x === null) {
      result = true;
    }
    if (typeof x === "undefined") {
      result = true;
    }
    return result;
  }

  floatToRoundRule(number) {
    let factor = 100;
    if (number >= 10) {
      factor = 1;
    }
    return Math.round(number * factor) / factor;
  }
  getThousandSeparatorFromNumber(num) {
    return (
      "" +
      this.floatToRoundRule(num)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, "'")
    );
  }

  get_chart_backgroundcolor(id) {
    const colors = {
      heat: "rgb(255, 83, 83)",
      electricity: "rgb(255, 204, 102)",
      mobility: "rgb(169, 209, 142)"
    };
    return colors[id];
  }
  getOrderedDictByOrderName(dict) {
    return dict.sort((a, b) => {
      return a.order - b.order;
    });
  }

  convertTooBigValue(value, unit) {
    if (value > 10 ** 7) {
      value /= 10 ** 6;
      unit += "_mio";
    }
    return [value, unit];
  }

  getNumberUnitIdFromFilterValue(filterValue) {
    if (filterValue == "pourcent") {
      var unit_from_config = "pct";
    } else if (filterValue == "habitant") {
      // var data_unit = `inhabitant_${this.chartData["datasets"][0]["unit"][0]}`;
      var unit_from_config = `per_inhabitant`;
    } else if (filterValue == "absolute") {
      // var data_unit = `${this.chartData["datasets"][0]["unit"][0]}`;
      var unit_from_config = `absolute_empty_string`;
    }
    return this.getUnitInfo(unit_from_config);
  }

  getUnitIdFromFilterValue(filterValue, unit) {
    if (filterValue == "pourcent") {
      var unit_from_config = "pct";
    } else if (filterValue == "habitant") {
      // var data_unit = `inhabitant_${this.chartData["datasets"][0]["unit"][0]}`;
      var unit_from_config = `inhabitant_${unit}`;
    } else if (filterValue == "absolute") {
      // var data_unit = `${this.chartData["datasets"][0]["unit"][0]}`;
      var unit_from_config = `${unit}`;
    }
    return this.getUnitInfo(unit_from_config);
  }

  getUnitInfo(unit) {
    const units = {
      kwh: {
        translate_unit: "energy.abs_kwh",
        convert_factor_from: {
          wh: 10 ** -3,
          kwh: 1,
          mwh: 10 ** 3,
          gwh: 10 ** 6,
          twh: 10 ** 9,
          kj: 1.0 / 3.6 * 10 ** -3,
          mj: 1.0 / 3.6,
          gj: 1.0 / 3.6 * 10 ** 3
        }
      },
      kw: {
        translate_unit: "power.abs_kw",
        convert_factor_from: {
          w: 10 ** -3,
          kw: 1,
          mw: 10 ** 3,
          gw: 10 ** 6,
          tw: 10 ** 9
        }
      },
      inhabitant_kwh: {
        translate_unit: "energy.inhabitant_kwh",
        convert_factor_from: {
          wh: 10 ** -3,
          mwh: 10 ** 3,
          gwh: 10 ** 6,
          twh: 10 ** 9,
          kj: 1.0 / 3.6 * 10 ** -3,
          mj: 1.0 / 3.6,
          gj: 1.0 / 3.6 * 10 ** 3
        }
      },
      inhabitant_mwh: {
        translate_unit: "energy.inhabitant_mwh",
        convert_factor_from: {
          wh: 10 ** -6,
          kwh: 10 ** -3,
          gwh: 10 ** 3,
          twh: 10 ** 6,
          kj: 1.0 / 3.6 * 10 ** -6,
          mj: 1.0 / 3.6 * 10 ** -3,
          gj: 1.0 / 3.6 * 10 ** 0
        }
      },
      mwh: {
        translate_unit: "energy.abs_mwh",
        convert_factor_from: {
          wh: 10 ** -6,
          kwh: 10 ** -3,
          mwh: 1,
          gwh: 10 ** 3,
          twh: 10 ** 6,
          kj: 1.0 / 3.6 * 10 ** -6,
          mj: 1.0 / 3.6 * 10 ** -3,
          gj: 1.0 / 3.6
        }
      },
      mw: {
        translate_unit: "power.abs_mw",
        convert_factor_from: {
          w: 10 ** -6,
          kw: 10 ** -3,
          mw: 1,
          gw: 10 ** 3,
          tw: 10 ** 6
        }
      },
      m2: {
        translate_unit: "area.abs_m2",
        convert_factor_from: {
          m2: 10 ** 0,
          ha: 10 ** 4
        }
      },
      per_inhabitant: {
        translate_unit: "various.per_inhabitant",
        convert_factor_from: {}
      },
      absolute_empty_string: {
        translate_unit: "various.",
        convert_factor_from: {}
      },
      inhabitants: {
        translate_unit: "various.inhabitants",
        convert_factor_from: {}
      },
      actions: {
        translate_unit: "various.actions",
        convert_factor_from: {}
      },
      power_facility: {
        translate_unit: "various.power_facility",
        convert_factor_from: {}
      },
      tco2: {
        translate_unit: "ghg.abs_ton",
        convert_factor_from: {
          kgco2: 10 ** -3,
          tco2: 10 ** 0
        }
      },
      inhabitant_tco2: {
        translate_unit: "ghg.inhabitant_ton",
        convert_factor_from: {
          kgco2: 10 ** -3,
          tco2: 10 ** 0
        }
      },
      kgco2: {
        translate_unit: "ghg.abs_kg",
        convert_factor_from: {
          kgco2: 10 ** -3
        }
      },
      pct: {
        translate_unit: "percentage.pct",
        convert_factor_from: {
          "%": 10 ** 0,
          pct: 10 ** 0,
          ratio: 10 ** 2
        }
      },
      vehicle: {
        translate_unit: "vehicle.abs_vehicle",
        convert_factor_from: {}
      },
      vehicle_per_capita: {
        translate_unit: "vehicle.inhabitant_vehicle",
        convert_factor_from: {
          veh_cap: 10 ** 0
        }
      },
      inhabitant_chf: {
        translate_unit: "money.inhabitant_chf",
        convert_factor_from: {
          chf: 10 ** 0,
          kchf: 10 ** 3,
          mchf: 10 ** 6
        }
      },
      chf: {
        translate_unit: "money.abs_chf",
        convert_factor_from: {
          chf: 10 ** 0,
          kchf: 10 ** 3,
          mchf: 10 ** 6
        }
      }
    };
    return units[unit];
  }
}
