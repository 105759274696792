import { Router } from '@angular/router';
import { Component, OnInit, AfterContentInit } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { LoaderService } from 'app/shared';
import { DataCollectionService } from 'app/shared/services/data-collection.service';
// import { LOGO_WELCOME, WELCOME_ADD_MESSAGE } from './../../shared/const';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit, AfterContentInit {
  petonlineStatus = false;
  private logoWelcome = [];
  private addMessageStatus = false;
  constructor(private router: Router, private dataCollectionService: DataCollectionService) { }

  ngOnInit() {
    this.dataCollectionService.getConfigFile().then(config => {
      this.logoWelcome = config["info-config"]["client-logo-on-welcome"];
      if (config["info-config"]["add-message"]){
        this.addMessageStatus = true;
      }
      else {
        this.addMessageStatus = false;
      }
    })
    this.dataCollectionService.webframeExists().then(data => {
      this.petonlineStatus = data;
    })     
  }
  ngAfterContentInit(): void {  }
  goToDashboard() {
    this.router.navigateByUrl('/dashboard')
  }
}
